/** @jsx jsx */
import React, { useEffect, useReducer } from "react"

import Amplify from "@aws-amplify/core";
import Storage from "@aws-amplify/storage"
// import Analytics from '@aws-amplify/analytics';
import { API,  graphqlOperation, Auth/*Storage,Cache, Hub, Logger*/ } from "aws-amplify"
import { listShotwireAssetsDeltas, listAssetsByCategory, listAssetsByUploader } from "../graphql/queries"
import { onCreateShotwireAssetsDelta, onUpdateShotwireAssetsDelta } from "../graphql/subscriptions"
import { createShotwireAssetsDelta, updateShotwireAssetsDelta } from "../graphql/mutations"
import { getAssetDeltaObject, getLegacyShotwireAsset, assetDeltaUpdate } from "../graphql/videoPlayerQueries"

import { Box, jsx } from 'theme-ui'
import { graphql, useStaticQuery, Link, navigate } from 'gatsby'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Button from 'react-bootstrap/Button'
import Image from 'react-bootstrap/Image'
import Form from 'react-bootstrap/Form'
import Card from 'react-bootstrap/Card'
import Accordion from 'react-bootstrap/Accordion'
import ProgressBar from 'react-bootstrap/ProgressBar'
import Alert from 'react-bootstrap/Alert'

import styled from '@emotion/styled'
import InfoCard from '../@geocine/gatsby-theme-organization/components/InfoCard'
import Layout from '../@geocine/gatsby-theme-organization/components/Layout'


import Helmet from 'react-helmet'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVideo, faDownload, faCartPlus, faHome, faEnvelope, faPhone, faPlay, faPause, faSpinner, faUpload, faTrashAlt, faTimesCircle, faFileVideo, faAngleDown, faAngleUp, faMinus, faPlus, faCheck } from "@fortawesome/free-solid-svg-icons";

import ReactPlayer from 'react-player'
import {v4 as uuid} from 'uuid';


// import Layout from "../components/layout"
// import SEO from "../components/seo"

import 'src/css/theme.scss'
import favicon from '../@geocine/gatsby-theme-organization/assets/favicon.ico'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { config } from '@fortawesome/fontawesome-svg-core'

import awsconfig from "../aws-exports";
import { AppsContext } from '../AppsProvider'

import { Picker, S3Image } from 'aws-amplify-react';

// window.LOG_LEVEL = 'DEBUG'

Amplify.configure(awsconfig);
// Storage.configure({ track: true })
// Prevent fontawesome from dynamically adding its css since we did it manually above
config.autoAddCss = false


const listText = 'rgba(0,0,0, .7)'//`${props => props.theme.colors.background}` 

const WatermarkLogoDiv = styled('div')`
  position: absolute;
  top: 20px;
  left: 35px;
  opacity: 0.6;
`

const UserOpsDiv = styled('div')`
  
  top: 10px;
  left: 0px;
  
`

const pickerButton = {
  marginLeft: '20px',
  padding: '12px 45px', 
  backgroundColor: 'red',
  color: 'white',
  fontSize: '.75rem',
  cursor: 'pointer',
  border: '1px solid red'
}

const MyTheme = {

    signInButtonIcon: { 'display': 'none' },
    googleSignInButton: { 'backgroundColor': 'red', 'borderColor': 'red' },
    button: { 'backgroundColor': 'red', 'border': '1px solid red' },
    navButton: { 'backgroundColor': 'red', 'borderColor': 'red' },
    photoPickerButton: {
        'backgroundColor': '#2e4ea3', 
        'border': '1px solid #2e4ea3' , 
        // position: 'relative',
         width: '100%',
         color: 'white',
                            // height: 'auto'
                            // objectFit: 'cover',
                            'borderRadius': '2px',
                            
                            // '&:hover': {
                            //   backgroundColor:'pink'
                            //    // WebkitFilter: 'grayscale(5%);',
                            //    // filter: 'grayscale(50%)',
                            // }
      }

}


// export const pickerPicker = {
// position: 'relative'
// };

// const AppTheme = {
// pickerPicker: pickerPicker
// }

// export default AppTheme;


const ControlCenterPage = () => {
  let userInfo = null, uRoles = null, uGroups = null
  // console.log('location.state:',location.state)
   
  // const [state, dispatch] = useReducer(reducer, initialState);
  const appsContext = React.useContext(AppsContext)
  let uploader = {}, wmUploader = {}, isProxyProcessing = [], isProxyDeleting = [], modifiedThumbsProx = [], modifiedThumbsArch = [], isProxyReProcessing = []

  // const uploadCompleted = appsContext.state.uploadCompleted, isUploading = appsContext.state.isUploading

  // const assetObj = (location.state === null) ? appsContext.state.videoPlayer.assetObj : location.state.assetObj
  
  // const vPlayer = { playing: false, isDownloading: false, hrDlSrc: '', assetObj: assetObj }

  
  // const onChange = (e) => {
  //     const file = e.target.files[0];
  //     Storage.put('example.png', file, {
  //         contentType: 'image/png'
  //     })
  //     .then (result => console.log(result))
  //     .catch(err => console.log(err));
  // }

  // const handleChange = (event) => {
  //   const { target: { value, files } } = event
  //   const fileForUpload = files[0]
  //   updateProductName(fileForUpload.name.split(".")[0])
  //   updateFile(fileForUpload || value)
  // }



  // async createProduct = (event) => {
  //   event.preventDefault()
  //   if (file) {
  //     const extension = file.name.split(".")[1]
  //     const { type: mimeType } = file
  //     const key = `images/${uuid()}${productName}.${extension}`      
  //     const url = `https://${bucket}.s3.${region}.amazonaws.com/public/${key}`
  //     const inputData = { name: productName , image: url }
      
  //     try {
  //       await Storage.put(key, file, {
  //         contentType: mimeType
  //       })
  //       console.log('inputData: ', inputData)
  //       await API.graphql(graphqlOperation(CreateProduct, { input: inputData }))
  //       console.log('successfully created product!')
  //     } catch (err) {
  //       console.log('error: ', err)
  //     }
  //   }
  // }
  function UploadImageErrorAlert() {
    // const [show, setShow] = useState(true);
    // console.log('errorAlerts:', appsContext.state.errorAlerts)

    if (appsContext.state.errorAlerts.UploadImageErrorAlert.show === true) {
      return (
        <Alert variant="danger" onClose={ () => { appsContext.dispatch({ type:"ERRORALERTS", errorAlerts: { ...appsContext.state.errorAlerts, UploadImageErrorAlert:{ show:false } } }) } } dismissible>
          <Alert.Heading>{appsContext.state.errorAlerts.UploadImageErrorAlert.heading}</Alert.Heading>
          <p>
            {appsContext.state.errorAlerts.UploadImageErrorAlert.body}
          </p>
        </Alert>
      )
    }else return <span></span>
    // return <Button onClick={ () => { } }>Show Alert</Button>;
  }

  function UploadVideoErrorAlert() {
    // const [show, setShow] = useState(true);
    // console.log('errorAlerts:', appsContext.state.errorAlerts)

    if (appsContext.state.errorAlerts.UploadVideoErrorAlert.show === true) {
      return (
        <Alert variant="danger" onClose={ () => { appsContext.dispatch({ type:"ERRORALERTS", errorAlerts: { ...appsContext.state.errorAlerts, UploadVideoErrorAlert:{ show:false } } }) } } dismissible>
          <Alert.Heading>{appsContext.state.errorAlerts.UploadVideoErrorAlert.heading}</Alert.Heading>
          <p>
            {appsContext.state.errorAlerts.UploadVideoErrorAlert.body}
          </p>
        </Alert>
      )
    }else return <span></span>
    // return <Button onClick={ () => { } }>Show Alert</Button>;
  }

    async function updateAssetDelta(assetObj) {
    try{
      // console.log('date:', assetObj.setDate)
      if(typeof assetObj.setDate !== 'undefined'){
            const formattedDate = [assetObj.setDate.slice(0, 4), assetObj.setDate.slice(4,6), assetObj.setDate.slice(6,8)].join('-')
            // console.log('formattedDate:', formattedDate)
            const eventDate = new Date(formattedDate).getTime()
            // console.log('eventDate:',eventDate)
          }
      // const asset = {...assetObj, archived: 1, category: '', timestamp: eventDate } 
      const asset = {...assetObj }      
     
      Object.keys(asset).forEach((key) => (asset[key] == null||asset[key] == '') && delete asset[key]);
      // console.log('asset->', asset)
      if(asset.category=='SELECT A CATEGORY' && asset.archived==1){
        // console.log('abort, category required to archive.')
        appsContext.dispatch({ type:"TOASTS", toasts:{ show:true, display:'block', displayTopPosition: '1500px', title:"Error", body: 'A CATEGORY Must be selected to Publish a video.' }})
      }else{

        if(asset.archived==0 || asset.archived=="0" || asset.archived==="false" || asset.archived===false){
          asset.category = "Ingest"
        }
        // console.log('asset=>', asset)
        // const savemeta = await API.graphql(graphqlOperation(assetDeltaUpdate, { input: asset }));
        const savemeta = await API.graphql(graphqlOperation(updateShotwireAssetsDelta, { input: asset }));
        
        
        // console.log('savemeta:', savemeta)
        appsContext.dispatch({ type: "METAUPDATE", isMetaSaving: false })
        appsContext.dispatch({ type: "SRCMEDIAINFOUPDATE", isSrcMediaInfoUpdating: false })

      }
      
    } catch(err) {
      appsContext.dispatch({ type: "METAUPDATE", isMetaSaving: false })
      appsContext.dispatch({ type: "SRCMEDIAINFOUPDATE", isSrcMediaInfoUpdating: false })
      // console.log('delta err:',err)
    }    
  }

  const handleDelete = (e, key) => {
    e.preventDefault()
    // console.log('key:', key)
    Storage.remove(key, {level: 'private'})
    .then(result => {
      // console.log('delete result:',result)
      appsContext.dispatch({ type: "FILEDELETECOUNTER", fileDeleteCounter: (appsContext.state.fileDeleteCounter+1) })
    })
    .catch(err =>{ 
      // console.log(err)
    });
  }

  const handleUnArchive = (e, key) => {
    e.preventDefault()
  }

  const handleProxyDelete = async (e, key) => {
    e.preventDefault()
    // console.log('key:', key)
    // console.log(appsContext.state.proxyList[key].id)

    isProxyDeleting = appsContext.state.isProxyDeleting
    isProxyDeleting[key] = true
    appsContext.dispatch({ type: "PROXYDELETE", isProxyDeleting })

    // appsContext.dispatch({ type: "PROXYDELETE", isDeletingProxy: true })
    const OgSrcVideoJobDelete = await API.get('shotwireAPI', '/createVideoProxy', {
                        'queryStringParameters': {
                          // 'setId': appsContext.state.videoPlayer.assetObj.realSetId,
                          // 'setDate': appsContext.state.videoPlayer.assetObj.setDate,
                          // 'setName': appsContext.state.videoPlayer.assetObj.name,
                          // 'assetName': (dlType === 'hrdl') ? appsContext.state.videoPlayer.assetObj.highRezFileName : appsContext.state.videoPlayer.assetObj.highRezFileName.replace('.mp4','-h264.mp4'),
                          'jobAction': 'OgSrcVideoDelete',
                          // 's3srcUrl' : srcObj.srcUrl,
                          'ogSrcFile': appsContext.state.proxyList[key].fileName,
                          // 'uploader' : appsContext.state.proxyList[key].uploader,
                          // 'jobTemplate': 'Shotwire-Custom-Job-User-'+ appsContext.state.user.username//'Shotwire-Watermarked-Proxies'  
                          // 'jobTemplate': 'Shotwire-Custom-Job-User-'+ appsContext.state.proxyList[key].uploader                    
                        }
                      });

    // console.log('OgSrcVideoJobDelete=>', OgSrcVideoJobDelete)

    deletaDeltaAsset(appsContext.state.proxyList[key].id)

    appsContext.state.proxyList.splice(key,1)
    
    setTimeout(() => {
        // console.log('finished process.');
        isProxyDeleting = appsContext.state.isProxyDeleting
        isProxyDeleting[key] = false
        appsContext.dispatch({ type: "PROXYDELETE", isProxyDeleting })
      }, 3000);

    // console.log('fileDeleteCounter:', appsContext.state.fileDeleteCounter)
    
  }

  const handleThumbnail = async (e, key, action, list) => {
    e.preventDefault()
    // console.log('e.target', e.target)
    // console.log('key:', key)
    // console.log('action:', action)
    // console.log('asset:', appsContext.state.proxyList[key].thumbNailUrl)
    let count = 0, go = false

    if(action === 'save'){
      //update the assets new thumbnailurl to the db
      if(list === 'proxy'){
        modifiedThumbsProx = appsContext.state.modifiedThumbsProx
        modifiedThumbsProx[key] = false
        appsContext.dispatch({ type: "MODIFIEDTHUMBSPROX", modifiedThumbsProx })
      }else{
        modifiedThumbsArch = appsContext.state.modifiedThumbsArch
        modifiedThumbsArch[key] = false
        appsContext.dispatch({ type: "MODIFIEDTHUMBSARCH", modifiedThumbsArch })
      }


      const assetObj = {
                            id: (list === 'proxy') ? appsContext.state.proxyList[key].id : appsContext.state.archiveList[key].id,
                            thumbNailUrl: (list === 'proxy') ? appsContext.state.proxyList[key].thumbNailUrl : appsContext.state.archiveList[key].thumbNailUrl
                          }
      // console.log('update assetObj:', assetObj)
      updateAssetDelta(assetObj)

      
      

    }

    count = (list === 'proxy') ? Math.round(appsContext.state.proxyList[key].thumbNailUrl.substr(-7,7).replace(/(\.jpg)/,'')) 
                                      : Math.round(appsContext.state.archiveList[key].thumbNailUrl.substr(-7,7).replace(/(\.jpg)/,'')) 
    // console.log('count:' , count)
    if(action === 'minus'){
       
      if(count>0){
         count--
         go = true
      }
        
   }
    if(action === 'plus'){
       // need to calculate possible max or check if url exists and fail gracefully
      if(count<1000){
        count++
        go = true
      }
      

    }

   if(list === 'proxy'
    && go === true
    ){
    // console.log('proxy thumb.')
        let pList = appsContext.state.proxyList
        pList[key].thumbNailUrl = appsContext.state.proxyList[key].thumbNailUrl.replace(/(\d\d\d\.jpg)/, ("00" + count).slice(-3) + '.jpg' )
        // console.log('pList:', pList)
        // check if thum exists?
        // console.log( "thumbs:", await getVideoThumbnails(pList[key].thumbNailUrl) ) 
        appsContext.dispatch({ type: "PROXYLIST", proxyListConnection: {items: pList, proxyListNextToken: null} });
        modifiedThumbsProx = appsContext.state.modifiedThumbsProx
        modifiedThumbsProx[key] = true
        appsContext.dispatch({ type: "MODIFIEDTHUMBSPROX", modifiedThumbsProx })
    }
    if(list == 'archive' 
      && go === true 
      ){
      // console.log('archive thumb.')
        let aList = appsContext.state.archiveList
        aList[key].thumbNailUrl = appsContext.state.archiveList[key].thumbNailUrl.replace(/(\d\d\d\.jpg)/, ("00" + count).slice(-3) + '.jpg' )
        // console.log('aList:', aList)
        appsContext.dispatch({ type: "ARCHIVELIST", archiveListConnection: {items: aList, archiveListNextToken: null} });
        modifiedThumbsArch = appsContext.state.modifiedThumbsArch
        modifiedThumbsArch[key] = true
        appsContext.dispatch({ type: "MODIFIEDTHUMBSARCH", modifiedThumbsArch })
    }

   
  }

  const handleDownload = (e, key) => {
    e.preventDefault()
    // console.log('key:', key)
    Storage.get(key, {
      level: 'private',
      // expires: 300
    })
    .then(result => {
      window.open(result, '_blank');
      //use navigate here to start auto download
      // console.log('download Link:',result)
          // navigate(
          //     result,
          //     // {
          //     //   state: { searchform },
          //     // }
          //   )
    })
    .catch(err =>{
      // console.log(err)
    });
  }

  const handleProxyDownload = (e, key) => {
    e.preventDefault()
    // console.log('key:', key)
    // console.log(appsContext.state.proxyList[key])
    // console.log(appsContext.state.proxyList[key].mp4Urls[1])
    window.open(appsContext.state.proxyList[key].mp4Urls[1].replace(/\{|\}/g,'').substr(2), '_blank');
    // "{S=https://d7byjd2oa0k4h.cloudfront.net/d28d692c-e0c4-4bbd-bf7d-d9114d0bb709/mp4/1e01704e-4019-4982-8cb1-6c0b3542576b_16995PCNVEX_HaileyClauson_TMF_Mp4_Avc_Aac_16x9_6400x360p_24Hz_4.5Mbps_qvbr.mp4}"

  }

  const handleHrDownload = async (e, key) => {
    e.preventDefault()
    console.log('HR key:', key)
    console.log(appsContext.state.archiveList[key])
    const ogSrcVidSignedUrl = getOgVideoFile(appsContext.state.archiveList[key].fileName)
    console.log('ogSrcVidSignedUrl:', ogSrcVidSignedUrl)
    // console.log(appsContext.state.proxyList[key].mp4Urls[1])
    // window.open(appsContext.state.proxyList[key].mp4Urls[1].replace(/\{|\}/g,'').substr(2), '_blank');
    // "{S=https://d7byjd2oa0k4h.cloudfront.net/d28d692c-e0c4-4bbd-bf7d-d9114d0bb709/mp4/1e01704e-4019-4982-8cb1-6c0b3542576b_16995PCNVEX_HaileyClauson_TMF_Mp4_Avc_Aac_16x9_6400x360p_24Hz_4.5Mbps_qvbr.mp4}"

  }

  async function checkProxyStatus(args) {
    
    const res = await API.get('shotwireAPI', '/createVideoProxy', {
                        'queryStringParameters': {
                          // 'setId': appsContext.state.videoPlayer.assetObj.realSetId,
                          // 'setDate': appsContext.state.videoPlayer.assetObj.setDate,
                          // 'setName': appsContext.state.videoPlayer.assetObj.name,
                          // 'assetName': (dlType === 'hrdl') ? appsContext.state.videoPlayer.assetObj.highRezFileName : appsContext.state.videoPlayer.assetObj.highRezFileName.replace('.mp4','-h264.mp4'),
                          'jobAction': 'OgSrcVideoJobInfo',
                          's3srcUrl' : args.srcUrl,
                          'ogSrcFile': args.filename,
                          // 'uploader' : appsContext.state.proxyList[key].uploader,
                          // 'jobTemplate': 'Shotwire-Custom-Job-User-'+ appsContext.state.user.username//'Shotwire-Watermarked-Proxies'  
                          'jobTemplate': 'Shotwire-Custom-Job-User-'+ args.uploader                    
                        }
                      });
    return res
  }

  const handleCheckStatus = async (e, key) => {
    e.preventDefault()
    // console.log('check status key, fileName:', key , appsContext.state.proxyList[key])
    try{
        let proceed = false
        if(typeof appsContext.state.proxyList[key].proxyInfo !== 'undefined' && appsContext.state.proxyList[key].proxyInfo !== null){
          console.log('Error (proxyInfo):', appsContext.state.proxyList[key].proxyInfo)

        }else proceed = true
        // console.log('src: ', appsContext.state.proxyList[key].fileName.replace(appsContext.state.proxyList[key].id+'_',''))
        // return filename

        // const asset = { id: newId, name:newId+'_'+appsContext.state.fileList[fileListIndex].key, fileName: newId+'_'+appsContext.state.fileList[fileListIndex].key, archived: 0, assetType: 3, category:'Ingest', workflowStatus: 'Ingest', uploader: appsContext.state.user.username }
        appsContext.dispatch({ type: "SRCMEDIAINFOUPDATE", isSrcMediaInfoUpdating: true })

        Storage.get(appsContext.state.proxyList[key].fileName.replace(appsContext.state.proxyList[key].id+'_',''), {
          level: 'private',
          // download: true, //get body , not just signed url , was gonna use this for copying to protected proxy directory
          // expires: 300
        }).then(async result => {
          // console.log('download Link:',result)
          // console.log('filename:', appsContext.state.proxyList[key].fileName)
          // console.log('uploader:', appsContext.state.proxyList[key].uploader)

          // const OgSrcVideoJobInfo = 
          return checkProxyStatus({  srcUrl: result, filename: appsContext.state.proxyList[key].fileName, uploader: appsContext.state.proxyList[key].uploader })
          
          // console.log('OgSrcVideoJobInfo:', OgSrcVideoJobInfo)

          
        
        }).then(result => {
          // console.log('proxy status:',result)

          if(typeof result.OgSrcVideoJobInfo !== 'undefined'){

            if(typeof result.OgSrcVideoJobInfo.error !== 'undefined'){
          
              appsContext.dispatch({ type:"TOASTS", toasts:{ show:true, display:'block', displayTopPosition:'-50px', title:"Error", body: result.OgSrcVideoJobInfo.error }})

            }

                    const mediainfo = JSON.parse(result.OgSrcVideoJobInfo[0].srcMediainfo)
                      console.log('mediainfo:', mediainfo )
                      // return OgSrcVideoJobInfo.OgSrcVideoJobInfo
                      if(result.OgSrcVideoJobInfo.length>0){                            
                          
                          const assetObj = {
                            id: appsContext.state.proxyList[key].id,
                            srcMediainfo: result.OgSrcVideoJobInfo[0].srcMediainfo,
                            thumbNailUrl: (typeof result.OgSrcVideoJobInfo[0].thumbNailUrl !== 'undefined') ? result.OgSrcVideoJobInfo[0].thumbNailUrl[0] : null,
                            mp4Urls: result.OgSrcVideoJobInfo[0].mp4Urls,
                            hlsUrl: result.OgSrcVideoJobInfo[0].hlsUrl,
                            dashUrl: result.OgSrcVideoJobInfo[0].dashUrl,
                            mp4Outputs: result.OgSrcVideoJobInfo[0].mp4Outputs,
                            video_length: mediainfo.container.duration/1000,
                            fileSize: mediainfo.container.fileSize/1024/1024,
                            video_width: mediainfo.video[0].width,
                            video_height: mediainfo.video[0].height,
                            video_aspect: mediainfo.video[0].aspectRatio,
                            video_frames: mediainfo.video[0].frameCount,
                            // video_format: String,
                            video_bitrate: mediainfo.video[0].bitrate,
                            video_fps: mediainfo.video[0].framerate ,
                            audio_codec: mediainfo.audio[0].codec,
                            // audio_format: mediainfo.audio[0].format,
                            audio_bitrate: mediainfo.audio[0].bitrate,
                            audio_rate: mediainfo.audio[0].samplingRate,
                            audio_length: mediainfo.audio[0].duration,        
        
                          }

                          if(typeof result.OgSrcVideoJobInfo[0].errorMessage !== 'undefined'){
                            assetObj.proxyInfo = result.OgSrcVideoJobInfo[0].errorMessage
                            // console.log('errorMessage:', result.OgSrcVideoJobInfo[0].errorMessage)
                          }

                          // console.log('input assetObj:', assetObj)
                          if(typeof assetObj.srcMediainfo !== 'undefined'){
                            // console.log('updating asset:.')
                             if(proceed === true) updateAssetDelta(assetObj)

                           }
                    }
          }
            appsContext.dispatch({ type: "SRCMEDIAINFOUPDATE", isSrcMediaInfoUpdating: false })


        }).catch(err =>{
          // console.log('get err:',err)
          appsContext.dispatch({ type: "SRCMEDIAINFOUPDATE", isSrcMediaInfoUpdating: false })
        });

        
        // console.log('OgSrcVideoJobInfo->', OgSrcVideoJobInfo)

        
        // return OgSrcVideoJobInfo.OgSrcVideoJobInfo

        
                
            
        // add video to delta table once proxy is created
        // appsContext.dispatch({type:"CREATEVIDEOPROXY", newAsset:{ name:srcObj.name, fileName: srcObj.name, archived: 0, assetType: 3, s3srcUrl: srcObj.srcUrl, proxyInfo: 'pending' } })
      // appsContext.addAssetDelta({ name:srcObj.name, fileName: srcObj.name, archived: 0, assetType: 3, s3srcUrl: srcObj.srcUrl, proxyInfo: 'pending' })
     
        
      }catch(err){
        //add an image placehold for assets with no image
        // dispatch({ type: "QUERY", albums: [{id:`none`,name:`none`}] });
        appsContext.dispatch({ type: "SRCMEDIAINFOUPDATE", isSrcMediaInfoUpdating: false })
        // console.log('media info error: ', err)
      }

  }

  async function getVideoThumbnails(thumbNailUrl){
    try{
        
        // console.log('filename: ', filename)
        // return filename
        const videoThumbs = await API.get('shotwireAPI', '/createVideoProxy', {
                        'queryStringParameters': {
                          // 'setId': appsContext.state.videoPlayer.assetObj.realSetId,
                          // 'setDate': appsContext.state.videoPlayer.assetObj.setDate,
                          // 'setName': appsContext.state.videoPlayer.assetObj.name,
                          // 'assetName': (dlType === 'hrdl') ? appsContext.state.videoPlayer.assetObj.highRezFileName : appsContext.state.videoPlayer.assetObj.highRezFileName.replace('.mp4','-h264.mp4'),
                          'jobAction': 'getVideoThumbnails',
                          // 's3srcUrl' : srcObj.srcUrl,
                          'thumbnailUrl' : thumbNailUrl,
                          // 'jobTemplate': 'Shotwire-Custom-Job-User-'+ appsContext.state.user.username//'Shotwire-Watermarked-Proxies'                      
                        }
                      });
        // console.log('ogSrcVidUrl:', ogSrcVidUrl)
        return videoThumbs
        

        // add video to delta table once proxy is created
        // appsContext.dispatch({type:"CREATEVIDEOPROXY", newAsset:{ name:srcObj.name, fileName: srcObj.name, archived: 0, assetType: 3, s3srcUrl: srcObj.srcUrl, proxyInfo: 'pending' } })
      // appsContext.addAssetDelta({ name:srcObj.name, fileName: srcObj.name, archived: 0, assetType: 3, s3srcUrl: srcObj.srcUrl, proxyInfo: 'pending' })
     
        
      }catch(err){
        //add an image placehold for assets with no image
        // dispatch({ type: "QUERY", albums: [{id:`none`,name:`none`}] });
        // console.log('error: ', err)
      }
  }

  async function getOgVideoFile(filename) {
    // console.log('image:', appsContext.state.videoPlayer.image)
    
      try{
        
        // console.log('filename: ', filename)
        // return filename
        const ogSrcVidUrl = await API.get('shotwireAPI', '/createVideoProxy', {
                        'queryStringParameters': {
                          // 'setId': appsContext.state.videoPlayer.assetObj.realSetId,
                          // 'setDate': appsContext.state.videoPlayer.assetObj.setDate,
                          // 'setName': appsContext.state.videoPlayer.assetObj.name,
                          // 'assetName': (dlType === 'hrdl') ? appsContext.state.videoPlayer.assetObj.highRezFileName : appsContext.state.videoPlayer.assetObj.highRezFileName.replace('.mp4','-h264.mp4'),
                          'jobAction': 'OgSrcVideoDownload',
                          // 's3srcUrl' : srcObj.srcUrl,
                          'ogSrcFile' : filename,
                          // 'jobTemplate': 'Shotwire-Custom-Job-User-'+ appsContext.state.user.username//'Shotwire-Watermarked-Proxies'                      
                        }
                      });
        // console.log('ogSrcVidUrl:', ogSrcVidUrl)
        return ogSrcVidUrl.ogSrcVideoUrl
        

        // add video to delta table once proxy is created
        // appsContext.dispatch({type:"CREATEVIDEOPROXY", newAsset:{ name:srcObj.name, fileName: srcObj.name, archived: 0, assetType: 3, s3srcUrl: srcObj.srcUrl, proxyInfo: 'pending' } })
      // appsContext.addAssetDelta({ name:srcObj.name, fileName: srcObj.name, archived: 0, assetType: 3, s3srcUrl: srcObj.srcUrl, proxyInfo: 'pending' })
     
        
      }catch(err){
        //add an image placehold for assets with no image
        // dispatch({ type: "QUERY", albums: [{id:`none`,name:`none`}] });
        // console.log('error: ', err)
      }
      

  }

  const handleArchiveDownload = (e, key) => {
    e.preventDefault()
    // console.log('key:', key)
    // console.log(appsContext.state.proxyList[key])
    // console.log(appsContext.state.proxyList[key].mp4Urls[1])
    window.open(appsContext.state.archiveList[key].mp4Urls[1].replace(/\{|\}/g,'').substr(2), '_blank');
    // "{S=https://d7byjd2oa0k4h.cloudfront.net/d28d692c-e0c4-4bbd-bf7d-d9114d0bb709/mp4/1e01704e-4019-4982-8cb1-6c0b3542576b_16995PCNVEX_HaileyClauson_TMF_Mp4_Avc_Aac_16x9_6400x360p_24Hz_4.5Mbps_qvbr.mp4}"

  }

  const handlePublish = (e, key) => {
    e.preventDefault()
    
    
   

  }

  const handleLicensing = (e, key) => {
    e.preventDefault()
    
    
   

  }

  
  const handleFileView = (e, fileListIndex) => {
    //tried this, don't do it. files won't play if not supported for some reason. must be converted first
    // so i guess just do automatic minimal conversions on upload to be able to play a file?
    e.preventDefault()
    let qstr = ''
    // console.log('key:', appsContext.state.fileList[fileListIndex].key)
    // console.log(appsContext.state.fileList[fileListIndex])
    let vPlayer = {}

    Storage.get(appsContext.state.fileList[fileListIndex].key, {
      level: 'private',
      // expires: 300
    })
    .then(result => {
       console.log('download Link:',result)
      vPlayer.signedUrl = result
      vPlayer.assetObj= appsContext.state.fileList[fileListIndex] 

      // console.log('vPlayer:',vPlayer)

      qstr = "key="+appsContext.state.fileList[fileListIndex].key
    
    navigate(
      "/VideoPlayer/?"+qstr,
      // {
      //   state: { searchform },
      // }
    )
    appsContext.dispatch({ type: "VPLAYER", vPlayer });
      //use navigate here to start auto download
      // console.log('download Link:',result)
          // navigate(
          //     result,
          //     // {
          //     //   state: { searchform },
          //     // }
          //   )
    })
    .catch(err =>{
      // console.log(err)
    });
    // console.log(appsContext.state.proxyList[key].mp4Urls[1])
    // console.log(appsContext.state.proxyList[fileListIndex].thumbNailUrl)
   
    // vPlayer.image = appsContext.state.proxyList[fileListIndex].thumbNailUrl
    // vPlayer.assetObj= appsContext.state.fileList[fileListIndex] 
    

    // if(appsContext.state.proxyList[fileListIndex].realSetId){
    //   qstr = "arsid="+appsContext.state.proxyList[fileListIndex].realSetId+"&asd="+appsContext.state.proxyList[fileListIndex].setDate+"&an="+appsContext.state.proxyList[fileListIndex].name
    // }else 
    
  }

  const handleMeta = (e, fileListIndex) => {
    e.preventDefault()
    let qstr = ''
    // console.log('key:', key)
    // console.log(appsContext.state.proxyList[fileListIndex])
    // console.log(appsContext.state.proxyList[key].mp4Urls[1])
    // console.log(appsContext.state.proxyList[fileListIndex].thumbNailUrl)
    let vPlayer = {}
    vPlayer.image = appsContext.state.proxyList[fileListIndex].thumbNailUrl
    vPlayer.assetObj= appsContext.state.proxyList[fileListIndex] 
    appsContext.dispatch({ type: "VPLAYER", vPlayer });

    if(appsContext.state.proxyList[fileListIndex].realSetId){
      qstr = "arsid="+appsContext.state.proxyList[fileListIndex].realSetId+"&asd="+appsContext.state.proxyList[fileListIndex].setDate+"&an="+appsContext.state.proxyList[fileListIndex].name
    }else qstr = "aid="+appsContext.state.proxyList[fileListIndex].id
    
    navigate(
      "/VideoPlayer/?"+qstr,
      // {
      //   state: { searchform },
      // }
    )
    // if(e.currentTarget.id === 'playButton') 


  }

  const handleArchiveMeta = (e, fileListIndex) => {
    e.preventDefault()
    let qstr = ''
    // console.log('key:', key)
    // console.log(appsContext.state.proxyList[fileListIndex])
    // console.log(appsContext.state.proxyList[key].mp4Urls[1])
    // console.log(appsContext.state.proxyList[fileListIndex].thumbNailUrl)
    let vPlayer = {}
    vPlayer.image = appsContext.state.archiveList[fileListIndex].thumbNailUrl
    vPlayer.assetObj= appsContext.state.archiveList[fileListIndex] 
    appsContext.dispatch({ type: "VPLAYER", vPlayer });

    if(appsContext.state.archiveList[fileListIndex].realSetId){
      qstr = "arsid="+appsContext.state.archiveList[fileListIndex].realSetId+"&asd="+appsContext.state.archiveList[fileListIndex].setDate+"&an="+appsContext.state.archiveList[fileListIndex].name
    }else qstr = "aid="+appsContext.state.archiveList[fileListIndex].id
    
    navigate(
      "/VideoPlayer/?"+qstr,
      // {
      //   state: { searchform },
      // }
    )
    // if(e.currentTarget.id === 'playButton') 


  }

  const updateUserWatermark = async (wmark) => {
    // console.log('wmark:',wmark)
    let user = await Auth.currentAuthenticatedUser();

    let userUpdate = await Auth.updateUserAttributes(user, {
        'picture': wmark       
      });
    //need to update the width and height object for the new image and update the form?
    // setTimeout( () => {
    //     console.log('appsContext.state.videoWatermark->', appsContext.state.videoWatermark)
    // }, 3000)
    
    // console.log('userUpdate->', userUpdate)
  }

  const handleOverlay = async (e, fileListIndex) => {

    //save the image to a public area so VOD processor can access it in proxy creation
    const fext = appsContext.state.fileList[fileListIndex].key.match(/(\.tga)|(\.png)/gi)
    // console.log('fext:', fext)
    const wmark = 'video-overlay-'+appsContext.state.user.username+fext
    Storage.get(appsContext.state.fileList[fileListIndex].key, {
      level: 'private',
      download: true, //get body , not just signed url
      // expires: 300
    })
    .then(result => {
      // console.log('download content:',result)
      Storage.put(wmark, result.Body, {
            // cacheControl: '', // (String) Specifies caching behavior along the request/reply chain
            // contentDisposition: '', // (String) Specifies presentational information for the object
            // expires: new Date().now() + 60 * 60 * 24 * 7, // (Date) The date and time at which the object is no longer cacheable. ISO-8601 string, or a UNIX timestamp in seconds
            metadata: { name: appsContext.state.fileList[fileListIndex].key }, // (map<String>) A map of metadata to store with the object in S3.
        })
        
     
    })
    .catch(err =>{
      
      // console.log(err)

    });

    let user = await Auth.currentAuthenticatedUser();

    let userUpdate = await Auth.updateUserAttributes(user, {
        'picture': wmark       
      });
    
    // console.log('userUpdate:',userUpdate);

  }

  const getUserAttributes = async () => {
    let user = await Auth.currentAuthenticatedUser();
    return user
  }

  const processPreviewProxy = (fileKey) => {
    const newId = uuid()

    // console.log('process fileKey:', fileKey)
    // console.log('user:', appsContext.state.user)

    const asset = { id: newId, name: fileKey, fileName: newId+'_'+fileKey, archived: 0, assetType: 3, category:'Ingest', workflowStatus: 'Ingest', uploader: appsContext.state.user.username/*, owner: appsContext.state.user.username */}
    // console.log('process asset:', asset)
      

    Storage.get(fileKey, {
      level: 'private',
      // download: true, //get body , not just signed url , was gonna use this for copying to protected proxy directory
      // expires: 300
    })
    .then(result => {
      // console.log('download Link:',result)
      addAssetDelta( asset ) 
      createVideoProxy({srcUrl: result, name: newId + '_' + fileKey})
      // Storage.put( newId + '_' + appsContext.state.fileList[fileListIndex].key, result.Body, {
      //   level: 'protected'
      //   })
     
    })
    // .then(result =>{
    //   // console.log('createVideoProxy result:', result)
    //   const asset = { id: newId, name: fileKey, fileName: newId+'_'+fileKey, archived: 0, assetType: 3, category:'Ingest', workflowStatus: 'Ingest', uploader: appsContext.state.user.username }
    //   console.log('asset:', asset)
    //   addAssetDelta( asset ) 
    //   //add ingest to proxy list
    //   // appsContext.dispatch({ type: "PROXYSTATS", isProxyProcessing: false })
      
    // })
    .catch(err =>{
      
      console.log(err)

    });
  }

  const handleProxy = (e, fileListIndex) => {
    e.preventDefault()
    
    // console.log('button target:', e.target)

    isProxyProcessing = appsContext.state.isProxyProcessing
    isProxyProcessing[fileListIndex] = true
    appsContext.dispatch({ type: "PROXYSTATS", isProxyProcessing })

    const newId = uuid()

    Storage.get(appsContext.state.fileList[fileListIndex].key, {
      level: 'private',
      // download: true, //get body , not just signed url , was gonna use this for copying to protected proxy directory
      // expires: 300
    })
    .then(result => {
      // console.log('download Link:',result)
      // createVideoProxy({srcUrl: result, name: appsContext.state.fileList[fileListIndex].key})
      createVideoProxy({srcUrl: result, name: newId + '_' + appsContext.state.fileList[fileListIndex].key})
      // Storage.put( newId + '_' + appsContext.state.fileList[fileListIndex].key, result.Body, {
      //   level: 'protected'
      //   })
     
    })
    .then(result =>{
      // console.log('createVideoProxy result:', result)
      // const asset = { id: newId, name:appsContext.state.fileList[fileListIndex].key, fileName: newId+'_'+appsContext.state.fileList[fileListIndex].key, archived: 0, assetType: 3, category:'Ingest', workflowStatus: 'Ingest', uploader: appsContext.state.user.username }
      const asset = { id: newId, name:newId+'_'+appsContext.state.fileList[fileListIndex].key, fileName: newId+'_'+appsContext.state.fileList[fileListIndex].key, archived: 0, assetType: 3, category:'Ingest', workflowStatus: 'Ingest', uploader: appsContext.state.user.username }
      
      // console.log('asset:', asset)
      addAssetDelta( asset ) 
      // appsContext.dispatch({ type: "PROXYSTATS", isProxyProcessing: false })
      
    })
    .catch(err =>{
      
      // console.log(err)

    });

     setTimeout(() => {
        // console.log('finished process.');
        isProxyProcessing = appsContext.state.isProxyProcessing
        isProxyProcessing[fileListIndex] = false
        appsContext.dispatch({ type: "PROXYSTATS", isProxyProcessing })
      }, 15000);
     
  }

  const handleReprocessVideo = (e, fileListIndex, list) => {
    e.preventDefault()
    
    isProxyReProcessing = appsContext.state.isProxyReProcessing
    isProxyReProcessing[fileListIndex] = true
    appsContext.dispatch({ type: "REPROXYSTATS", isProxyReProcessing })
    // console.log('fileListIndex:', fileListIndex)
    // console.log('list:', list)
    // console.log('e:', e)
    let asset = null

    if(list === 'proxy'){
      // console.log('proxyList:',   appsContext.state.proxyList[fileListIndex])
      asset = appsContext.state.proxyList[fileListIndex]
    }
    if(list === 'archive'){
      // console.log('archiveList:', appsContext.state.archiveList[fileListIndex])
      asset = appsContext.state.archiveList[fileListIndex]
    }

    // console.log('asset id:', asset.id)
    // console.log('srcFilename:', asset.fileName)

    const srcObj = {srcUrl: asset.fileName, name: asset.fileName, isReprocessVideo: true}
    // console.log('srcObj:', srcObj)
    createVideoProxy(srcObj)

    setTimeout(() => {
        // console.log('finished process.');
        isProxyReProcessing = appsContext.state.isProxyReProcessing
        isProxyReProcessing[fileListIndex] = false
        appsContext.dispatch({ type: "REPROXYSTATS", isProxyReProcessing })
      }, 15000);

  }

  const handleDupeProxy = (e, fileListIndex, list) => {
    e.preventDefault()
    
    // console.log('fileListIndex:', fileListIndex)
    // console.log('list:', list)
    // console.log('e:', e)
    isProxyProcessing = appsContext.state.isProxyProcessing
    isProxyProcessing[fileListIndex] = true
    appsContext.dispatch({ type: "PROXYSTATS", isProxyProcessing })

    let asset = null

    if(list === 'proxy'){
      // console.log('proxyList:',   appsContext.state.proxyList[fileListIndex])
      asset = appsContext.state.proxyList[fileListIndex]
    }
    if(list === 'archive'){
      // console.log('archiveList:', appsContext.state.archiveList[fileListIndex])
      asset = appsContext.state.archiveList[fileListIndex]
    }
    // console.log('button target:', e.target)

    // isProxyProcessing = appsContext.state.isProxyProcessing
    // isProxyProcessing[fileListIndex] = true
    // appsContext.dispatch({ type: "PROXYSTATS", isProxyProcessing })

    const newId = uuid()

    // console.log('srcFilename:', asset.fileName)
    let filter = asset.fileName.match(/_/)
    // console.log('filter:', filter)
    // console.log('srcFilename substr:', asset.fileName.substr(filter["index"]) )


    // console.log('new file name:', newId+asset.fileName.substr(filter["index"]))

    const nuasset = { id: newId, s3srcUrl: asset.fileName, name: newId+asset.fileName.substr(filter["index"]), fileName: newId+asset.fileName.substr(filter["index"]), archived: 0, assetType: 3, category:'Ingest', workflowStatus: 'Ingest', uploader: appsContext.state.user.username }
    // console.log('nuasset:', nuasset)
    addAssetDelta( nuasset ) 
    const srcObj = {srcUrl: asset.fileName, name: newId+asset.fileName.substr(filter["index"]), useSystemSrcFile: true}
    // console.log('srcObj:', srcObj)
    createVideoProxy(srcObj)
    
    setTimeout(() => {
        // console.log('finished process.');
        isProxyProcessing = appsContext.state.isProxyProcessing
        isProxyProcessing[fileListIndex] = false
        appsContext.dispatch({ type: "PROXYSTATS", isProxyProcessing })
      }, 15000);
    // Storage.get(appsContext.state.fileList[fileListIndex].key, {
    //   level: 'private',
    //   // download: true, //get body , not just signed url , was gonna use this for copying to protected proxy directory
    //   // expires: 300
    // })
    // .then(result => {
    //   // console.log('download Link:',result)
    //   createVideoProxy({srcUrl: result, name: newId + '_' + appsContext.state.fileList[fileListIndex].key})
    //   // Storage.put( newId + '_' + appsContext.state.fileList[fileListIndex].key, result.Body, {
    //   //   level: 'protected'
    //   //   })
     
    // })
    // .then(result =>{
    //   // console.log('createVideoProxy result:', result)
    //   const asset = { id: newId, name:newId+'_'+appsContext.state.fileList[fileListIndex].key, fileName: newId+'_'+appsContext.state.fileList[fileListIndex].key, archived: 0, assetType: 3, category:'Ingest', workflowStatus: 'Ingest', uploader: appsContext.state.user.username }
    //   // console.log('asset:', asset)
    //   addAssetDelta( asset ) 
    //   // appsContext.dispatch({ type: "PROXYSTATS", isProxyProcessing: false })
      
    // })
    // .catch(err =>{
      
    //   // console.log(err)

    // });

    //  setTimeout(() => {
    //     // console.log('finished process.');
    //     isProxyProcessing = appsContext.state.isProxyProcessing
    //     isProxyProcessing[fileListIndex] = false
    //     appsContext.dispatch({ type: "PROXYSTATS", isProxyProcessing })
    //   }, 15000);
     
  }

  const handleUpload = (data, arg) => {

          /* Do something when the user has signed-in */
          // try{
          //   getVideoUrl('hrdl')
          //   vPlayer.isDownloading = true
          //   appsContext.dispatch({ type: "VPLAYER", vPlayer });
          
          //   // appsContext.dispatch({ type: "SRC", signedUrl: signedUrl.videoUrl });
          // }catch(err){
            
          //   console.log('error: ', err)
          // }
        // console.log('arg: ', arg)
        // console.log('data: ', data)
          // Storage.put(data.name, data.file, {
          //     level: 'private',
          //     contentType: data.type
          // })
          // .then (result => console.log(result))
          // .catch(err => console.log(err));

          // //below is the react native version that supports blobs
          // uploadImage = async uri => {
          //   const response = await fetch(uri);
          //   const blob = await response.blob();
          //   const fileName = 'profileImage.jpeg';
          //   await Storage.put(fileName, blob, {
          //     contentType: 'image/jpeg',
          //     level: 'private'
          //   }).then(data => console.log(data))
          //     .catch(err => console.log(err))
          // }
  }

  const handleImgSize = (e) => {
    e.preventDefault()
    // console.log('get img size', e)
    // console.log('e.target.naturalWidth:', e.target.naturalWidth) //e.target.offsetHeight
    // console.log('e.target.naturalHeight:', e.target.naturalHeight) //e.target.offsetWidth
    // console.log('e.target:', e.target.src)
    // console.log('get img size.')
    //update user form
    appsContext.dispatch({type:"VIDEOWATERMARK", videoWatermark:{ width: e.target.naturalWidth, height: e.target.naturalHeight, src:e.target.src } })
    appsContext.dispatch({type:"WMARKFORM", wmarkForm: {...appsContext.state.wmarkForm, width: e.target.naturalWidth, height: e.target.naturalHeight} })
    // console.log('check videoWatermark:', appsContext.state.videoWatermark)

  }

  const handleJobTemplate = (e) => {
    e.preventDefault()
    // console.log('update job template')
    appsContext.dispatch({ type: "JOBTEMPLATE", jobTemplate:{ isUpdating:true, doJobTemplateUpdate:0 } })
    createJobTemplate('update')
  }


    async function createVideoProxy(srcObj) {
    // console.log('image:', appsContext.state.videoPlayer.image)
    
      try{
        
        // console.log('hrDlSrc: ',appsContext.state.videoPlayer.hrDlSrc)
        const createProxy = await API.get('shotwireAPI', '/createVideoProxy', {
                        'queryStringParameters': {
                          // 'setId': appsContext.state.videoPlayer.assetObj.realSetId,
                          // 'setDate': appsContext.state.videoPlayer.assetObj.setDate,
                          // 'setName': appsContext.state.videoPlayer.assetObj.name,
                          // 'assetName': (dlType === 'hrdl') ? appsContext.state.videoPlayer.assetObj.highRezFileName : appsContext.state.videoPlayer.assetObj.highRezFileName.replace('.mp4','-h264.mp4'),
                          'jobAction': 'createProxy',
                          's3srcUrl' : srcObj.srcUrl,
                          'assetName' : srcObj.name,
                          'jobTemplate': 'Shotwire-Custom-Job-User-'+ appsContext.state.user.username,//'Shotwire-Watermarked-Proxies'  
                          'useSystemSrcFile': (srcObj.useSystemSrcFile === true ? true:false  ),
                          'isReprocessVideo': (srcObj.isReprocessVideo === true ? true:false  )         
                        }
                      });
        // console.log('createProxy:', createProxy)
        return createProxy
        // add video to delta table once proxy is created
        // appsContext.dispatch({type:"CREATEVIDEOPROXY", newAsset:{ name:srcObj.name, fileName: srcObj.name, archived: 0, assetType: 3, s3srcUrl: srcObj.srcUrl, proxyInfo: 'pending' } })
      // appsContext.addAssetDelta({ name:srcObj.name, fileName: srcObj.name, archived: 0, assetType: 3, s3srcUrl: srcObj.srcUrl, proxyInfo: 'pending' })
     
        
      }catch(err){
        //add an image placehold for assets with no image
        // dispatch({ type: "QUERY", albums: [{id:`none`,name:`none`}] });
        // console.log('error: ', err)
      }
      

  }

  async function createJobTemplate(caller='') {
    // console.log('image:', appsContext.state.videoPlayer.image)
      // console.log('caller:',caller)
      // console.log('current loaded w, form w:', appsContext.state.videoWatermark.width , appsContext.state.wmarkForm.width)
      try{
        // console.log('appsContext.state.videoWatermark:',appsContext.state.videoWatermark)
        // console.log('hrDlSrc: ',appsContext.state.videoPlayer.hrDlSrc)
        //*
        const imgW = (caller === '') ? appsContext.state.videoWatermark.width : appsContext.state.wmarkForm.width
        const imgH = (caller === '') ? appsContext.state.videoWatermark.height : appsContext.state.wmarkForm.height

        // console.log('img w,h:', imgW, imgH)

        const fext = appsContext.state.videoWatermark.src.match(/(\.tga)|(\.png)/gi)
        // console.log('fext:', fext)
        const jobTempl = await API.get('shotwireAPI', '/createVideoProxy', {
                        'queryStringParameters': {
                          'jobAction': 'createShotwireVODJobTempate',
                          'userVideoWatermarkFile':'video-overlay-'+appsContext.state.user.username+fext,
                          // 'imageWidth':appsContext.state.videoWatermark.width,
                          // 'imageHeight':appsContext.state.videoWatermark.height,
                          // 'assetName' : srcObj.name,
                          'newJobTemplateName': 'Shotwire-Custom-Job-User-'+ appsContext.state.user.username,// + '-' + (new Date().getTime())  

                          "width": imgW,
                          "height": imgH,
                          'imageX': appsContext.state.wmarkForm.top,
                          'imageY': appsContext.state.wmarkForm.left,
                          "layer": appsContext.state.wmarkForm.layer,
                          // "ImageInserterInput": "s3://assets.shotloadr.com/shotwire-logo.png",
                          'opacity': appsContext.state.wmarkForm.opacity                  
                        }
                      });
        // console.log('jobTempl:', jobTempl)
        appsContext.dispatch({ type: "JOBTEMPLATE", jobTemplate:{ isUpdating:false, doJobTemplateUpdate: 0} })
        return jobTempl
        //*/
        // add video to delta table once proxy is created
        // appsContext.dispatch({type:"CREATEVIDEOPROXY", newAsset:{ name:srcObj.name, fileName: srcObj.name, archived: 0, assetType: 3, s3srcUrl: srcObj.srcUrl, proxyInfo: 'pending' } })
      // appsContext.addAssetDelta({ name:srcObj.name, fileName: srcObj.name, archived: 0, assetType: 3, s3srcUrl: srcObj.srcUrl, proxyInfo: 'pending' })
     
        
      }catch(err){
        // console.log('error: ', err)
        //add an image placehold for assets with no image
        // dispatch({ type: "QUERY", albums: [{id:`none`,name:`none`}] });
        appsContext.dispatch({ type: "JOBTEMPLATE", jobTemplate:{isUpdating:false, doJobTemplateUpdate: 0} })
        
      }
      

  }

  const handleWmFormChange = (e) => {
    // console.log('e.target.id:',e.target.id)
    // console.log('e.target.value:',e.target.value)
    // console.log('metaField:',metaField)
    
    // const metaForm = {}
    // metaForm[e.target.id] = e.target.value
    // console.log('appsContext.state.wmarkForm', appsContext.state.wmarkForm)
    // appsContext.dispatch({type:'WMARKFORM', metaForm: {...appsContext.state.wmarkForm, [e.target.id]:e.target.value} })
    if(e.target.id==='wmTop'){
      // console.log('year')
      // console.log('e.target.value:',e.target.value)
      // 0 is top
      appsContext.dispatch({type:'WMARKFORM', wmarkForm: { ...appsContext.state.wmarkForm, top: e.target.value} })
    }else if(e.target.id==='wmLeft'){
      // console.log('month')
      // console.log('e.target.value:',e.target.value)
      // 0 is most left
      appsContext.dispatch({type:'WMARKFORM', wmarkForm: { ...appsContext.state.wmarkForm, left: e.target.value} })
    }else if(e.target.id==='wmOpacity'){
      // console.log('day')
      // console.log('e.target.value:',e.target.value)
      // 0-100 , 0 is not visible
      appsContext.dispatch({type:'WMARKFORM', wmarkForm: {...appsContext.state.wmarkForm, opacity: e.target.value} })
    }else if(e.target.id==='wmWidth'){
      // console.log('e.target.value:',e.target.value)
      appsContext.dispatch({type:'WMARKFORM', wmarkForm: {...appsContext.state.wmarkForm, width: e.target.value} })
    }else if(e.target.id==='wmHeight'){
      // console.log('e.target.value:',e.target.value)
      appsContext.dispatch({type:'WMARKFORM', wmarkForm: {...appsContext.state.wmarkForm, height: e.target.value} })
    }else if(e.target.id==='wmLayer'){
      // console.log('e.target.value:',e.target.value)
      // 0-99 , one layer per graphic , higher overlaps lower values
      appsContext.dispatch({type:'WMARKFORM', wmarkForm: {...appsContext.state.wmarkForm, layer: e.target.value} })
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    appsContext.dispatch({ type: "METAUPDATE", isMetaSaving: true })
    // console.log('Submit target:',e.target)
    // console.log('Submit target.category:',e.target.headline.value)
    // console.log('appsContext.state.videoPlayer.assetObj:', appsContext.state.videoPlayer.assetObj)
    const assetObj = {
      id: appsContext.state.videoPlayer.assetObj.id,
      archived: e.target.archived.value,
      category: e.target.category.value,
      setDate: e.target.setDateYear.value + e.target.setDateMonth.value + e.target.setDateDay.value,
      headline: e.target.headline.value,
      caption: e.target.caption.value,
      byline: e.target.byline.value,
      keywords: e.target.keywords.value,
      suppCat: e.target.suppCat.value,
      city: e.target.city.value,
    }
    const eventDate = new Date(e.target.setDateYear.value +'-'+ e.target.setDateMonth.value  +'-'+ e.target.setDateDay.value).getTime()
    // console.log('eventDate:', eventDate)
    // console.log('assetObj:', assetObj)
  if(typeof e.target.forceUnpublish != 'undefined'){
    // console.log('e.target.forceUnpublish.value:',e.target.forceUnpublish.value)
    if(e.target.forceUnpublish.value == 'on'){
      // console.log('updating asset.')
      // updateAssetDelta(assetObj)
    }else{
      // console('abort save')
    }
   
  }else{
    // console.log('updating asset..')
    // updateAssetDelta(assetObj)
  }
  
    
    

  }
  const createAssetDelta = `mutation createAssetDelta($input: CreateShotwireAssetsDeltaInput!) {
      createShotwireAssetsDelta(input: $input) {
        id
        assetId
        name
        uploader
        fileName
        workflowStatus
        category
        assetType
        archived


      }
    }
    `;

  async function addAssetDelta(assetObj) {
    // const asset = {...assetObj, archived: 1}
      // console.log('props:',props)
      // console.log('albumid:',albumid)
      // const photo = { 
      //   id: id,
      //   // owner: props.album.owner,
      //   photoAlbumId: albumid,
      //   // bucket: bucketName,
      //   // thumbnail: sizes.thumbnail,
      //   // fullsize: sizes.fullsize,
      //   // createdAt: new Date().getTime()
      // };
    try{
      // console.log('added assetObj:', assetObj)
      // await API.graphql(graphqlOperation(createAssetDelta, { input: assetObj }));
      await API.graphql(graphqlOperation(createShotwireAssetsDelta, { input: assetObj }));
        

    } catch (err){
      // console.log('addasset err:',err)
    }
    
    }

    const deleteAssetDelta = `mutation deleteAssetDelta($input: DeleteShotwireAssetsDeltaInput!){
      deleteShotwireAssetsDelta(input: $input){
        id
        assetId
        name

      }
    }`

    async function deletaDeltaAsset(id) {
    
      
      try{
        //delete the the asset from the Shotwire VOD hub

        const result = await API.graphql(
          // graphqlOperation(deleteAssetDelta, { input: {id: id} })
            {
                query: deleteAssetDelta,
                variables: { input: {id: id} },
                authMode:  (appsContext.state.isAdmin===true || appsContext.state.isUploader===true || appsContext.state.user!==null)  ? 'AMAZON_COGNITO_USER_POOLS': 'AWS_IAM'
            }
          );
        // appsContext.dispatch({ type: "FILEDELETECOUNTER", fileDeleteCounter: (appsContext.state.fileDeleteCounter+1) })
        // console.log('delete result:', result)

      } catch (err){
        // console.log('delete asset err:',err)
      }
      
    
    }

    async function unArchiveAsset(id) {
    
      
      try{
        // await API.graphql(graphqlOperation(deleteAssetDelta, { input: {id: id} }));
        // appsContext.dispatch({ type: "FILEDELETECOUNTER", fileDeleteCounter: (appsContext.state.fileDeleteCounter+1) })
      } catch (err){
        // console.log('addasset err:',err)
      }
      
    
    }


    // const proxyListQuery = `query ListAllshotwireAssetsDeltas($filter: ModelShotwireAssetsDeltaFilterInput, $limit: Int, $nextToken: String){
    //   listShotwireAssetsDeltas(filter: $filter, limit: $limit, nextToken: $nextToken){
    //     items {
    //       id
    //       fileName
    //       fileInfo
    //       fileSize
    //       assetType
    //       highRezFileName
    //       companyId
    //       name
    //       setDate
    //       realSetId
    //       assetId
    //       headline
    //       caption
    //       keywords
    //       byline
    //       suppCat
    //       city
    //       video_length
    //       archived
    //       thumbNailUrl
    //       mp4Urls
    //       hlsUrl
    //       dashUrl
    //       mp4Outputs
    //       category
    //     }
    //     nextToken
        
    //   }
    // }`

    // const proxyListIndexQuery = `query ListAssetsDeltasIndex($category: String!,$filter: TableShotwireAssetsDeltaFilterInput, $limit: Int, $nextToken: String, $scannedCount: Int){
    //   listShotAssetsDeltasIndex(category: $category, filter: $filter ,limit: $limit, nextToken: $nextToken, scannedCount: $scannedCount){
    //     items {
    //       id
    //       category
    //       setDate 
    //       name
    //       fileName
    //       fileInfo
    //       fileSize
    //       assetType
    //       highRezFileName
    //       companyId
    //       realSetId
    //       assetId
    //       headline
    //       caption
    //       keywords
    //       byline
    //       suppCat
    //       city
    //       video_length
    //       archived
    //       thumbNailUrl
    //       mp4Urls
    //       hlsUrl
    //       dashUrl
    //       mp4Outputs
    //       uploader

    //     }
    //     nextToken
    //     scannedCount
    //   }
    // }`

    // const ListShotAssetsDeltasUploaderIndex = `query ListShotAssetsDeltasUploaderIndex($uploader: String!, $category: String!, $filter: TableShotwireAssetsDeltaFilterInput, $limit: Int, $nextToken: String){
    //   listShotAssetsDeltasUploaderIndex(uploader: $uploader, category:$category ,filter: $filter ,limit: $limit, nextToken: $nextToken){
    //     items {
    //       id
    //       category
    //       fileName
    //       fileInfo
    //       fileSize
    //       assetType
    //       highRezFileName
    //       companyId
    //       name
    //       setDate
    //       realSetId
    //       assetId
    //       headline
    //       caption
    //       keywords
    //       byline
    //       suppCat
    //       city
    //       video_length
    //       archived
    //       thumbNailUrl
    //       mp4Urls
    //       hlsUrl
    //       dashUrl
    //       mp4Outputs
    //       uploader
    //     }
    //     nextToken
    //   }
    // }`
// listShotAssetsDeltasIndex(category: "Ingest", filter: {archived: {eq: 0}} ,limit: 50, nextToken: null){
//         items {
//           id
//           fileName
//           fileInfo
//           fileSize
//           assetType
//           highRezFileName
//           companyId
//           name
//           setDate
//           realSetId
//           assetId
//           headline
//           caption
//           keywords
//           byline
//           suppCat
//           city
//           video_length
//         }
//         nextToken
//       }
//     }
    async function getAdminProxyList() {
    
    // console.log('getting getAdminProxyList...')
      try{
        // console.log('userInfo.username:', userInfo.username)
        // console.log('appsContext.state.proxyListNextToken:',appsContext.state.proxyListNextToken)
        // const proxyList = await API.graphql(graphqlOperation(proxyListIndexQuery,{category: "Ingest",filter: {or :[{archived: {eq: 0}}, {category: {eq:"Ingest"}}]}, limit: 9999999, nextToken: appsContext.state.proxyListNextToken}));
        // const proxyList = await API.graphql(graphqlOperation(proxyListQuery, {filter:{ archived: {eq:0}}, limit:100, nextToken:null }));
        const proxyList = await API.graphql(graphqlOperation(listAssetsByCategory, {category: "Ingest", limit:100, nextToken:null }));
        

        appsContext.dispatch({ type: "PROXYLIST", proxyListConnection: {items: proxyList.data.listAssetsByCategory.items, proxyListNextToken: appsContext.state.proxyListNextToken} });
        


//should be this but commenting out for now
        // const proxyList = await API.graphql(graphqlOperation(proxyListIndexQuery,{uploader: appsContext.state.user.username ,category: "Ingest", filter: null, limit: 100, nextToken: appsContext.state.proxyListNextToken}));

        // console.log('proxyList:', proxyList)
        // appsContext.dispatch({ type: "PROXYLIST", proxyListConnection: {items: proxyList.data.listShotAssetsDeltasIndex.items, proxyListNextToken: appsContext.state.proxyListNextToken} });
        



      }catch(err){
        
        //add an image placehold for assets with no image
        // appsContext.dispatch({ type: "QUERY", albums: [{id:`none`,name:`none`, headline:`none`}] });
        // console.log('error: ', err)
      }
      

    }

    async function getMyProxyList() {
    
    // console.log('getting getMyProxyList...')
      try{
        // console.log('userInfo.username:', userInfo.username)
        // console.log('appsContext.state.proxyListNextToken:',appsContext.state.proxyListNextToken)
        // const proxyList = await API.graphql(graphqlOperation(proxyListIndexQuery,{category: "Ingest",filter: {or :[{archived: {eq: 0}}, {category: {eq:"Ingest"}}]}, limit: 9999999, nextToken: appsContext.state.proxyListNextToken}));
        // const proxyList = await API.graphql(graphqlOperation(proxyListQuery,{filter: {archived: {eq: 0}}, limit: 999999, nextToken: null}));
        // const proxyList = await API.graphql(graphqlOperation(ListShotAssetsDeltasUploaderIndex,{uploader: appsContext.state.user.username ,category: "Ingest", filter: null, limit: 100, nextToken: appsContext.state.proxyListNextToken}));
        const proxyList = await API.graphql(graphqlOperation(listAssetsByUploader,{uploader: appsContext.state.user.username ,category: {eq: "Ingest"}, filter: null, limit: 100, nextToken: appsContext.state.proxyListNextToken}));

        // console.log('proxyList:', proxyList)
        appsContext.dispatch({ type: "PROXYLIST", proxyListConnection: {items: proxyList.data.listAssetsByUploader.items, proxyListNextToken: appsContext.state.proxyListNextToken} });
        



      }catch(err){
        
        //add an image placehold for assets with no image
        // appsContext.dispatch({ type: "QUERY", albums: [{id:`none`,name:`none`, headline:`none`}] });
        // console.log('proxy error: ', err)
      }
      

    }

    async function getMyArchiveList() {
    
    // console.log('getting getMyArchiveList...')
      try{
        // console.log('userInfo.username:', userInfo.username)
        // console.log('appsContext.state.proxyListNextToken:',appsContext.state.proxyListNextToken)
        // const proxyList = await API.graphql(graphqlOperation(proxyListIndexQuery,{category: "Ingest",filter: {or :[{archived: {eq: 0}}, {category: {eq:"Ingest"}}]}, limit: 9999999, nextToken: appsContext.state.proxyListNextToken}));
        // const proxyList = await API.graphql(graphqlOperation(proxyListQuery,{filter: {archived: {eq: 0}}, limit: 999999, nextToken: null}));
        // const proxyList = await API.graphql(graphqlOperation(ListShotAssetsDeltasUploaderIndex,{uploader: appsContext.state.user.username ,category: "Ingest", filter: null, limit: 100, nextToken: appsContext.state.proxyListNextToken}));
        const archiveList = await API.graphql(graphqlOperation(listAssetsByUploader,{uploader: appsContext.state.user.username, filter: {archived:{eq:"true"}}, limit: 100, nextToken: appsContext.state.archiveListNextToken}));

        // console.log('archiveList:', archiveList)
        appsContext.dispatch({ type: "ARCHIVELIST", archiveListConnection: {items: archiveList.data.listAssetsByUploader.items, archiveListNextToken: appsContext.state.archiveListNextToken} });
        



      }catch(err){
        
        //add an image placehold for assets with no image
        // appsContext.dispatch({ type: "QUERY", albums: [{id:`none`,name:`none`, headline:`none`}] });
        // console.log('error: ', err)
      }
      

    }

  // const handlePlay = (e) => {
  //   e.preventDefault()
  //   console.log('onPlay')
  //   // setState({ playing: true })
  //   vPlayer.playing = true
  //   appsContext.dispatch({ type: "VPLAYER",  vPlayer });
  // }

  // const handlePause = (e) => {
  //   if (e){ 
  //     e.preventDefault()
  //     console.log('onPause')
  //     // setState({ playing: false })
  //     vPlayer.playing = false
  //     appsContext.dispatch({ type: "VPLAYER",  vPlayer });
  //       // event.preventDefault();
  //       // console.log('props.location.pathname:', props.location.pathname)
  //         // console.log('clicked menu link:', event.target)
  //         // currentCategory = category
  //         // appsContext.dispatch({ type: "VPLAYER",  currentCategory});
  //     }
  // }
//   const onUpdateShotwireAssetsDelta = `subscription OnUpdateShotwireAssetsDelta {
//   onUpdateShotwireAssetsDelta {
//     id
//     fileName
//     category
//     uploader
//     fileInfo
//     fileSize
//     assetType
//     highRezFileName
//     companyId
//     name
//     setDate
//     realSetId
//     assetId
//     headline
//     caption
//     keywords
//     byline
//     suppCat
//     city
//     state
//     country
//     video_length
//     archived
//     thumbNailUrl
//     mp4Urls
//     hlsUrl
//     dashUrl
//     mp4Outputs
//   }
// }
// `;

const onCreateShotwireAssetsDeltaTest = /* GraphQL */ `
  subscription OnCreateShotwireAssetsDelta($owner: String, $uploader: String) {
    onCreateShotwireAssetsDelta(owner: $owner, uploader: $uploader) {
      id
      assetId
      assetType
      name
      fileName
      fileInfo
      fileSize
      highRezFileName
      highRezDirectory
      archived
      s3srcUrl
      proxyInfo
      apiSource
      thumbNailUrl
      hlsUrl
      dashUrl
      cmafDashUrl
      cmafDashPlaylist
      cmafHlsUrl
      cmafHlsPlaylist
      workflowStatus
      category
      searches
      uploader
      srcMediainfo
    }
  }
`;

// const onCreateShotwireAssetsDelta = /* GraphQL */ `
//   subscription OnCreateShotwireAssetsDelta {
//     onCreateShotwireAssetsDelta {
//       id
//       assetType
//       fileName
//       fileInfo
//       fileSize
//       highRezFileName
//       name
//       realSetId
//       setDate
//       companyId
//       releaseDate
//       createdDate
//       modified
//       archived
//       s3srcUrl
//       proxyInfo
//       apiSource
//       thumbNailUrl
//       workflowStatus
//       category
//       searches
//       uploader
//       timestamp
//       license
//       marketplace
//       srcMediainfo
//     }
//   }
// `;

  useEffect(() => {
    // console.log('check for job template update...')
    // console.log('appsContext.state.jobTemplate.doJobTemplateUpdate:', appsContext.state.jobTemplate.doJobTemplateUpdate)
    if(appsContext.state.jobTemplate.doJobTemplateUpdate === 1){
      // console.log('job template waiting for update...')
      // console.log('wmarkForm=',appsContext.state.wmarkForm)
      createJobTemplate()
      // setTimeout(() => {
      //     console.log('job template updating...')
      //     console.log('wmarkForm:',appsContext.state.wmarkForm)
      //     // appsContext.dispatch({ type: "JOBTEMPLATE", jobTemplate:{ doJobTemplateUpdate:1, isUpdating:true } })
      //     createJobTemplate()
      // }, 11000) 
      
    }else{
      // console.log('no job update.')
    }

  },[ appsContext.state.videoWatermark ])

  useEffect(() => {
      // console.log('get for user product and stats...')
      let isMounted = true
      if(isMounted === true){
        // console.log('setting up stripe element...')

          if(appsContext.state.user){
            const  userInfo  = appsContext.state.user
            // console.log('userInfo: ', userInfo)

            if(typeof userInfo.signInUserSession.idToken.payload[`cognito:groups`] != 'undefined'){
              uGroups = userInfo.signInUserSession.idToken.payload[`cognito:groups`]
              // console.log('uGroups:',uGroups)
              // console.log('uRoles: ', uRoles)
              if( uGroups.some(r => ['Admins'].indexOf(r) >= 0) ){
                // console.log('Admin user.')
                 appsContext.dispatch({ type: "ISADMIN", isAdmin:true }) 
               }
              if( uGroups.some(r => ['Uploaders'].indexOf(r) >= 0) ){
                // console.log('Uploader user.')
                 appsContext.dispatch({ type: "ISUPLOADER", isUploader:true }) 
               }
              // console.log('isAdmin:', isAdmin)
            }

         
          }
        }

        return () => {
                  //console.log('state2: ', state)
                  // subscription.unsubscribe();
                  isMounted = false
                  
                };

  },[])

  useEffect(() => {
    let proxyCreateSubscription = null

      try{

        getMyProxyList()

        // console.log('appsContext.state.user.username:', appsContext.state.user.username)

        proxyCreateSubscription = API.graphql(graphqlOperation(onCreateShotwireAssetsDeltaTest, { owner: appsContext.state.user.username, uploader: appsContext.state.user.username })).subscribe({
                  error: err => console.log("sub err:", err),
                  next: eventData => {
                    // console.log('eventData:', eventData)
                    const newAsset = eventData.value.data.onCreateShotwireAssetsDelta;
                    // console.log('newAsset:',newAsset)
                    // console.log('appsContext.state.proxyList',appsContext.state.proxyList)
                    
                    if(newAsset !== null){
                        const replaceAsset = (element) => element.id === newAsset.id;
                        
                        let pList = appsContext.state.proxyList
                        // console.log('pList:', pList)
    
                        // pList.push(newAsset)
    
                        const replaceIndex = pList.findIndex(replaceAsset)
                        // console.log('replace index:' , replaceIndex)
                        // console.log('asset to replace: ', pList[replaceIndex] )
                       
                        // if(newAsset.uploader === appsContext.state.user.username){
                          if(replaceIndex !== -1) pList[replaceIndex] = newAsset//pList.push(newAsset)
                          else pList.push(newAsset)
                        // }
                        // console.log('on create pList:', pList)
                        // dispatch({ type: "SUBSCRIPTION", newAsset });
                        appsContext.dispatch({ type: "PROXYLIST", proxyListConnection: {items: pList, proxyListNextToken: appsContext.state.proxyListNextToken} });
                    }
                    // else{ 
                    //   console.log('Error Subscription newAsset is null')
                    // }
                  }
                });

      } catch (error) {

        // console.log('useEffect error: ',error)

      }  
      return () => {
                  if(proxyCreateSubscription != null) proxyCreateSubscription.unsubscribe();
                  // Hub.remove(channel: string | RegExp, listener: callback)
                  // Hub.remove( 'auth' )
                  // vPlayer.videoUrl = '' 
                  // vPlayer.signedUrl = ''
                  // vPlayer.hrDlSrc = ''
                  // vPlayer.assetObj= {} 
                  // appsContext.dispatch({ type: "VPLAYER", vPlayer });

                };
          
  },[ userInfo ])

  useEffect(() => {
    let subscription = null
    
        try{
             
                // getProxyList()
                // if(appsContext.state.isUploader) 
                  // getMyProxyList()
                  getMyArchiveList()
                // if(appsContext.state.isAdmin) getAdminProxyList()
                // console.log('storage listing uploader...', appsContext.state.isUploader)
                // console.log('storage listing admin....', appsContext.state.isAdmin)
                if(appsContext.state.isUploader===true || appsContext.state.isAdmin===true) Storage.list('', {level: 'private'})
                  .then(async result => {
                    // console.log('storage listing: ',result)
                    appsContext.dispatch({ type: "FILELIST", fileList: result })
                  })
                  .catch(
                    err => {
                      // console.log('storage list err:',err)
                    })
              // getData();
              
              //NEED TO ONLY UPDATE THE USERS OWN PROXIES!! IT IS UPDATING WITH ANY USERS VIDEOS!
              subscription = API.graphql(graphqlOperation(onUpdateShotwireAssetsDelta, { owner: appsContext.state.user.username, uploader: appsContext.state.user.username })).subscribe({
                  error: err => console.log("sub err:", err),
                  next: eventData => {
                    const newAsset = eventData.value.data.onUpdateShotwireAssetsDelta;
                    // console.log('updated Asset:',newAsset)
                    // console.log('appsContext.state.proxyList',appsContext.state.proxyList)
                    if(newAsset !== null){
                                        const replaceAsset = (element) => element.id === newAsset.id;
                                        
                                        let pList = appsContext.state.proxyList
                                        // console.log('pList:', pList)
                                        // pList.push(newAsset)
                    
                                        const replaceIndex = pList.findIndex(replaceAsset)
                                        // console.log('replace index:' , replaceIndex)
                                        // console.log('asset to replace: ', pList[replaceIndex] )
                                       
                                        // if(newAsset.uploader === appsContext.state.user.username){
                                          if(replaceIndex !== -1) pList[replaceIndex] = newAsset//pList.push(newAsset)
                                          else pList.push(newAsset)
                                        // }
                                        // console.log('pList2:', pList)
                                        // dispatch({ type: "SUBSCRIPTION", newAsset });
                                        appsContext.dispatch({ type: "PROXYLIST", proxyListConnection: {items: pList, proxyListNextToken: appsContext.state.proxyListNextToken} });
                            }
                            // else{
                            //   console.log('Subscription Update asset error. newAsset is null')
                            // }
                  }
                });
          } catch (error) {
            // console.log('useEffect error: ',error)
          }     

                return () => {
                  if(subscription != null) subscription.unsubscribe();
                  // Hub.remove(channel: string | RegExp, listener: callback)
                  // Hub.remove( 'auth' )
                  // vPlayer.videoUrl = '' 
                  // vPlayer.signedUrl = ''
                  // vPlayer.hrDlSrc = ''
                  // vPlayer.assetObj= {} 
                  // appsContext.dispatch({ type: "VPLAYER", vPlayer });

                };
          
      }, [ appsContext.state.uploadCounter, appsContext.state.fileDeleteCounter, appsContext.state.isProxyProcessing, userInfo, uRoles, appsContext.state.isAdmin, appsContext.state.isUploader, appsContext.state.wmUploader, appsContext.state.isProxyDeleting ]);

  


  //settting auth state to 'loading' hide the default signin form
if (appsContext.state.user !== null) {
  // const userInfo = appsContext.state.user
//get user roles is admin
// console.log('admin->', {isAdmin})
  if(appsContext.state.isAdmin || appsContext.state.isUploader){
     return(
          <Layout style={{color:appsContext.state.theme.bodyTextColor}}>
          <Helmet>
            <title>Shotwire - Video Dashboard</title>
            <link rel="icon" href={favicon} type="image/x-icon" />
          </Helmet>
         <Container sx={{
          // color:listText
          }}>
          <div sx={{
             
              padding: '10px',
              // color: listText
              // backgroundColor: '#888'
              // borderBottom: '1px dashed #333'
          }}>
          <h5>VIDEO DASHBOARD</h5>
          </div>
          
          
            <Row sx={{color:listText}}>
              <Col xs={{ span:12, order: 4 }} sm={{ span:12, order: 4 }} lg={{ span:9, order: 1 }}>              
                <div sx={{
                    padding: '10px',
                // backgroundColor: 'blue',
                width: '100%',
                height: 'auto'
                // objectFit: 'contain'
                      }}>

<Row sx={{
              borderBottom: '2px solid gray',
                    padding: '1rem',
                backgroundColor: '#FFF',// whiteBg,
                // width: '100%',
                // height: 'auto'
                // objectFit: 'contain'
                      }}>


<Col sm={12} lg={12} sx={{
  // textAlign:'right'
}}>
{
  // <h4>Video Uploads - ({appsContext.state.fileList.length}) Private </h4>
 //  <div sx={{
 //  paddingLeft: '10px',
 // }}>Total: {appsContext.state.fileList.length}</div>


}
<UploadVideoErrorAlert />
                      <Picker 
                        title={ (appsContext.state.isUploading) ? (<span> Uploading <FontAwesomeIcon
                                              icon={faSpinner}
                                              size="sm"
                                              sx={{
                                                // marginRight: '0.5rem'
                                              }}
                                              spin
                                            /> </span>) :  
                                    ' Upload New Video '
                                             }
                        theme={
                            { photoPickerButton: {
                                                              'backgroundColor': '#2e4ea3', 
                                                              'border': 'none' , 
                                                              'padding': '10px',
                                                              // position: 'relative',
                                                               // width: '100%',
                                                               color: 'white',
                                                                                  // height: 'auto'
                                                                                  // objectFit: 'cover',
                                                                                  'borderRadius': '3px',
                                                                                  
                                                                                  '&:hover': {
                                                                                    backgroundColor:'pink'
                                                                                     // WebkitFilter: 'grayscale(5%);',
                                                                                     // filter: 'grayscale(50%)',
                                                                                  },
                                                                                  display: (appsContext.state.isUploading ? 'none':'block')
                                                            }}
                          }
                        onPick={ data => {
                            // console.log(data)
                            uploader.isUploading = true

                            const fext = data.name.match(/(\.mov)|(\.mp4)|(\.m4v)|(\.m4p)|(\.mpeg)|(\.mpg)|(\.avi)|(\.wmv)|(\.flv)/gi)
                            // console.log('fext:', fext)
                            
                            // match "video" and reject if not a video
                            // console.log('data.type:', data.type)
                            uploader.fileName = data.name
                            
                            if(data.type.match(/video/i)){
                                
                                // console.log('video type ok.')
                                Storage.put(data.name, data.file, {
                                  level: 'private',
                                  contentType: data.type,
                                  progressCallback(progress) {
                                        // console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
                                        
                                        uploader.uploadCompleted = progress.loaded/progress.total
                                        // appsContext.state.uploadCompleted = progress.loaded/progress.total
                                        appsContext.dispatch({ type: "UPLOADER", uploader })
                                  }
                                })
                                .then (result => {
                                  // console.log(result)
                                  uploader.isUploading = false
                                  uploader.uploadCompleted = 0
                                  uploader.uploadCounter++
                                  appsContext.dispatch({ type: "UPLOADER", uploader })
                                  processPreviewProxy(data.name)
                                })
                                .catch(err => {
                                  // console.log(err)
                                  uploader.isUploading = false
                                  uploader.uploadCompleted = 0
                                  uploader.uploadCounter++
                                  appsContext.dispatch({ type: "UPLOADER", uploader })
                                });

                            }else{

                              // console.log('not a video!')
                              // appsContext.dispatch({ type:"TOASTS", toasts:{ show:true, display:'block', displayTopPosition:'-50px', title:"Error", body: "Upload Video Files Only!" }})
                              appsContext.dispatch({ type:"ERRORALERTS", errorAlerts: { ...appsContext.state.errorAlerts, UploadVideoErrorAlert:{ show:true, heading:"Error", body: "Upload Video Files Only!" } } })

                            }


                            
                          }


                        }
                        // onClick={e => handleUpload(e, 'videoUpload')}
                        track
                        >
                      </Picker>
{
                      (appsContext.state.isUploading) ?
                      
                      <span>
                      <FontAwesomeIcon
                        icon={faSpinner}
                        size="sm"
                        sx={{
                          // marginRight: '0.5rem'
                        }}
                        spin
                      /> <b> Uploading File: {appsContext.state.uploadFileName}</b><br />
                      <ProgressBar now={Math.round(appsContext.state.uploadCompleted*100)} label={`${Math.round(appsContext.state.uploadCompleted*100)}%`} />
                      </span>
                      :
                      ''
                   }
                    
                    
                    </Col>
</Row>
{
  (appsContext.state.isAdmin === true && appsContext.state.showPrivateFileList === true) 
  ?
 appsContext.state.fileList.map((file,key) => (
            <Row key={key} sx={{
              borderBottom: '1px dashed rgba(0,0,0, .2)',
                    padding: '1rem',
                backgroundColor: '#FFF',// whiteBg, //
                color: listText
                // width: '100%',
                // height: 'auto'
                // objectFit: 'contain'
                      }}>
            <Col sm={12} lg={6}><b>{key+1}.</b> {file.key}
            {
              (file.key.match(/(\.tga)|(\.png)/gi)) ?

                      <S3Image imgKey={file.key} 
                        level="private" 
                        // style={{display: 'inline-block', 'padding': '5px'}}
                        theme={{
                          photoImg: {
                            width: '70%',
                            // height: 'auto'
                            // objectFit: 'cover',
                            // borderRadius: '10%',
                            
                            // &:hover: {
                            //    WebkitFilter: 'grayscale(100%);',
                            //    filter: 'grayscale(100%)',
                            // }
                          }
                        }}
                        track />:null
            }
            </Col>
            <Col sm={12} lg={6}>
                      
                    {  
                      //check for video or photo file
                      // console.log('file:', file)
                    }
                    {
                    // <Button sx={{ margin: '2px' }} variant="dark" href="#!" size="md" onClick={e => handleFileView(e, key)}>View</Button>
                    }
                    {
                      (file.key.match(/(\.tga)|(\.png)/gi)) ?

                      <Button variant="dark" onClick={e => handleOverlay(e, key)}>Set Video Overlay</Button>

                      :
                      <Button id={`createProxyButton_`+key} sx={{ margin: '2px' }} variant="dark" href="#!" size="md" onClick={e => handleProxy(e, key)} disabled={appsContext.state.isProxyProcessing[key]}>
                                          {(appsContext.state.isProxyProcessing[key]) ? 
                                            (<span> Processing <FontAwesomeIcon
                                              icon={faSpinner}
                                              size="sm"
                                              sx={{
                                                // marginRight: '0.5rem'
                                              }}
                                              spin
                                            /> </span>)
                                            : 
                                            ('Create Proxies')
                                          }
                                            </Button> 

                    }
                    <Button sx={{ margin: '2px' }} variant="dark" href="#!" size="md" onClick={e => handleDownload(e, file.key)}>Download</Button> 
                      <Button sx={{ margin: '2px' }} variant="dark" href="#!" size="md" onClick={e => handleDelete(e, file.key)}>
                        <FontAwesomeIcon
                    icon={faTimesCircle}
                    size="lg"
                    sx={{
                      // marginRight: '0.1rem'
                    }}
                  />
                      </Button>
            </Col>
            </Row>
          ))
  : ''
}
                    </div>
                  <div sx={{
                    padding: '10px',
                // backgroundColor: 'blue',
                width: '100%',
                height: 'auto'
                // objectFit: 'contain'
                      }}>
<Row sx={{
              borderBottom: '2px solid gray',
                    padding: '1rem',
                backgroundColor: '#5e5e5e',
                color: 'white'
                // width: '100%',
                // height: 'auto'
                // objectFit: 'contain'
                      }}>
<h4>Videos ({appsContext.state.proxyList.length}) </h4>
{ 
  appsContext.state.isProxyProcessing.includes(true) ? (
          <div sx={{
  paddingLeft: '10px',
  color: 'white'
 }} >
          <FontAwesomeIcon
            icon={faSpinner}
            size="sm"
            sx={{
              // marginRight: '0.5rem'
            }}
            spin
          /> Video is processing. A preview thumbnail will show in the list below when processing is done.
          </div>
        ) : (
          <div sx={{
  paddingLeft: '10px',
 }}></div>
        )
    }
</Row>
{

 appsContext.state.proxyList.map((file,key) => (

            <Row key={key} sx={{
              borderBottom: '1px dashed rgba(0,0,0, .2)',
                    padding: '1rem',
                backgroundColor: '#dbdbdb',
                // width: '100%',
                // height: 'auto'
                // objectFit: 'contain'
                      }}>
            <Col sm={12} lg={6}><b>{key+1}.</b> {file.name}<br />
              {(file.thumbNailUrl!==null) ? 
                <div>
                  <span><b>Uploader:</b> {file.uploader}</span><br />
                  <Image 
                  src={file.thumbNailUrl}
                  alt={file.name}
                  height={168}
                  // width={295}
                  // width={'100%'}
                  // width={ (assetId=='none') ? `300` : '100%'}
                  // fluid
                  thumbnail
                  />
                  </div>

                : <span>
                  <b>Video preview processing</b><br />
                  A preview thumbnail will show here when done in a few minutes.
                  </span>}
            </Col>
          
           
          
            <Col sm={12} lg={6}>
{
  (file.thumbNailUrl!==null) ? 
<div>
              <Button sx={{ margin: '2px' }} variant="dark" href="#!" size="md" onClick={e => handleMeta(e, key)}>Meta / Publish</Button> 
                <Button sx={{ margin: '2px' }} variant="dark" href="#!" size="md" onClick={e => handleProxyDownload(e, key)}>Proxy Download</Button> 
                <Button sx={{ margin: '2px' }} variant="dark" href="#!" size="md" onClick={e => handleHrDownload(e, key)}>Original File Download</Button>    
                {
                                (appsContext.state.isAdmin===true) ?
                                            
                                <Button id={`createProxyButton_`+key} sx={{ margin: '2px' }} variant="dark" href="#!" size="md" onClick={e => handleDupeProxy(e, key, 'proxy')} disabled={appsContext.state.isProxyProcessing[key]}>
                                                          {(appsContext.state.isProxyProcessing[key]) ? 
                                                            (<span> Processing <FontAwesomeIcon
                                                              icon={faSpinner}
                                                              size="sm"
                                                              sx={{
                                                                // marginRight: '0.5rem'
                                                              }}
                                                              spin
                                                            /> </span>)
                                                            : 
                                                            ('Create New Proxies')
                                                          }
                                                            </Button> 
                                                            : <span></span>
                                                          }

                                                          {
                                (appsContext.state.isAdmin===true) ?
                                            
                                <Button id={`createProxyButton_`+key} sx={{ margin: '2px' }} variant="dark" href="#!" size="md" onClick={e => handleReprocessVideo(e, key, 'proxy')} disabled={appsContext.state.isProxyProcessing[key]}>
                                                          {(appsContext.state.isProxyReProcessing[key]) ? 
                                                            (<span> Processing <FontAwesomeIcon
                                                              icon={faSpinner}
                                                              size="sm"
                                                              sx={{
                                                                // marginRight: '0.5rem'
                                                              }}
                                                              spin
                                                            /> </span>)
                                                            : 
                                                            ('Re-process')
                                                          }
                                                            </Button> 
                                                            : <span></span>
                                                          }

</div>
                : 

                <Button sx={{ margin: '2px' }} variant="dark" href="#!" size="md" onClick={e => handleCheckStatus(e, key)}>
                   {
              (appsContext.state.isSrcMediaInfoUpdating===true) ? 
                          (<span> Checking Status <FontAwesomeIcon
                            icon={faSpinner}
                            size="sm"
                            sx={{
                              // marginRight: '0.5rem'
                            }}
                            spin
                          /> </span>)
                          : 
                          ('Check Status')
                        }
                

                </Button>
                
                }
    
    {
      // console.log( 'current thumb #:',Math.round(file.thumbNailUrl.substr(-7,7).replace(/(\.jpg)/,'')) )
    }            

                {
                // <Button sx={{ margin: '2px' }} variant="dark" href="#!" size="md" onClick={e => handlePublish(e, key)} disabled>Publish</Button> 
                }
                <ButtonGroup aria-label="Thumbnail">
                  <Button sx={{ margin: '2px' }} variant="dark" href="#!" size="md" disabled>Thumbnail</Button>
                  <Button sx={{ margin: '2px' }} variant="dark" href="#!" size="md" onClick={e => handleThumbnail(e, key, 'minus','proxy')}>
                <FontAwesomeIcon
                            icon={faMinus}
                            size="sm"
                            sx={{
                              // marginRight: '0.1rem'
                            }}
                          />
                </Button>
                <Button sx={{ margin: '2px' }} variant="dark" href="#!" size="md" onClick={e => handleThumbnail(e, key, 'plus','proxy')}>
                <FontAwesomeIcon
                            icon={faPlus}
                            size="sm"
                            sx={{
                              // marginRight: '0.1rem'
                            }}
                          />
                </Button>
                {
                  <Button sx={{ margin: '2px' }} variant="dark" href="#!" size="md" onClick={e => handleThumbnail(e, key, 'save','proxy')} disabled={!appsContext.state.modifiedThumbsProx[key]}>
                  <FontAwesomeIcon
                              icon={faCheck}
                              size="sm"
                              sx={{
                                // marginRight: '0.1rem'
                              }}
                            />
                  </Button>
                }
                </ButtonGroup>
                <br />
                <Button sx={{ margin: '2px' }} variant="dark" href="#!" size="md" onClick={e => handleProxyDelete(e, key)}>
                {
              (appsContext.state.isProxyDeleting[key]===true) ? 
                          (<span> Deleting <FontAwesomeIcon
                            icon={faSpinner}
                            size="sm"
                            sx={{
                              // marginRight: '0.5rem'
                            }}
                            spin
                          /> </span>)
                          : 
                          <span> Delete <FontAwesomeIcon
                            icon={faTimesCircle}
                            size="lg"
                            sx={{
                              // marginRight: '0.1rem'
                            }}
                          /></span>
                        }
                
                </Button>

                
                

            </Col>

          </Row>
          
          ))
}
<Row sx={{
              borderBottom: '2px solid gray',
                    padding: '1rem',
                backgroundColor: '#4d6396',
                color: 'white'
                // width: '100%',
                // height: 'auto'
                // objectFit: 'contain'
                      }}>
<h4>Video Archive ({appsContext.state.archiveList.length})</h4>
{
 //  <div sx={{
 //  paddingLeft: '10px',
 // }}>Total: {appsContext.state.archiveList.length}</div>
}
</Row>
{

 appsContext.state.archiveList.map((file,key) => (

            <Row key={key} sx={{
              borderBottom: '1px dashed rgba(0,0,0, .2)',
                    padding: '1rem',
                backgroundColor: '#e8efff',
                // width: '100%',
                // height: 'auto'
                // objectFit: 'contain'
                      }}>
            <Col sm={12} lg={6}><b>{key+1}.</b> {file.name}<br />
              {(file.thumbNailUrl!==null) ? 
                <div>
                  <span><b>Uploader:</b> {file.uploader}</span><br />
                  <Image 
                  src={file.thumbNailUrl}
                  alt={file.name}
                  height={168}
                  // width={295}
                  width={'100%'}
                  // width={ (assetId=='none') ? `300` : '100%'}
                  // fluid
                  />
                  </div>

                : <b>MISSING THUMBNAIL</b>}
            </Col>
          
           
          
            <Col sm={12} lg={6}>
                <Button sx={{ margin: '2px' }} variant="dark" href="#!" size="md" onClick={e => handleArchiveMeta(e, key)}>Edit Meta</Button> 
                <Button sx={{ margin: '2px' }} variant="dark" href="#!" size="md" onClick={e => handleArchiveDownload(e, key)}>Watermarked Download</Button>
                <Button sx={{ margin: '2px' }} variant="dark" href="#!" size="md" onClick={e => handleHrDownload(e, key)}>Original File Download</Button> 
                
{
                                (appsContext.state.isAdmin===true) ?
                <Button id={`createProxyButton_`+key} sx={{ margin: '2px' }} variant="dark" href="#!" size="md" onClick={e => handleDupeProxy(e, key,'archive')} disabled={appsContext.state.isProxyProcessing[key]}>
                                          {
                                            (appsContext.state.isProxyProcessing[key]) ? 
                                            (<span> Processing <FontAwesomeIcon
                                              icon={faSpinner}
                                              size="sm"
                                              sx={{
                                                // marginRight: '0.5rem'
                                              }}
                                              spin
                                            /> </span>)
                                            : 
                                            ('Create New Proxies')
                                          }
                                            </Button> 
                                            : 
                                            <span></span>
                                          }

                                          {
                                (appsContext.state.isAdmin===true) ?
                                            
                                <Button id={`createProxyButton_`+key} sx={{ margin: '2px' }} variant="dark" href="#!" size="md" onClick={e => handleReprocessVideo(e, key, 'archive')} disabled={appsContext.state.isProxyProcessing[key]}>
                                                          {(appsContext.state.isProxyReProcessing[key]) ? 
                                                            (<span> Processing <FontAwesomeIcon
                                                              icon={faSpinner}
                                                              size="sm"
                                                              sx={{
                                                                // marginRight: '0.5rem'
                                                              }}
                                                              spin
                                                            /> </span>)
                                                            : 
                                                            ('Re-process')
                                                          }
                                                            </Button> 
                                                            : <span></span>
                                                          }
                
                {
                // <Button sx={{ margin: '2px' }} variant="dark" href="#!" size="md" onClick={e => handlePublish(e, key)} disabled>Publish</Button> 
                }
                <Button sx={{ margin: '2px' }} variant="dark" href="#!" size="md" onClick={e => handleUnArchive(e, key)}>
                Un-Archive
                </Button>
                <Button sx={{ margin: '2px' }} variant="dark" href="#!" size="md" onClick={e => handleLicensing(e, key)}>
                {
                  (file.marketplace !== 'false') ? 'Licensing - Enabled' : 'Licensing - Disabled'
                }
                </Button>

                <ButtonGroup aria-label="Basic example">
                  <Button sx={{ margin: '2px' }} variant="dark" href="#!" size="md" disabled>Thumbnail</Button>
                  <Button sx={{ margin: '2px' }} variant="dark" href="#!" size="md" onClick={e => handleThumbnail(e, key, 'minus','archive')}>
                <FontAwesomeIcon
                            icon={faMinus}
                            size="sm"
                            sx={{
                              // marginRight: '0.1rem'
                            }}
                          />
                </Button>
                <Button sx={{ margin: '2px' }} variant="dark" href="#!" size="md" onClick={e => handleThumbnail(e, key, 'plus','archive')}>
                <FontAwesomeIcon
                            icon={faPlus}
                            size="sm"
                            sx={{
                              // marginRight: '0.1rem'
                            }}
                          />
                </Button>
                {
                  <Button sx={{ margin: '2px' }} variant="dark" href="#!" size="md" onClick={e => handleThumbnail(e, key, 'save','archive')} disabled={!appsContext.state.modifiedThumbsArch[key]}>
                  <FontAwesomeIcon
                              icon={faCheck}
                              size="sm"
                              sx={{
                                // marginRight: '0.1rem'
                              }}
                            />
                  </Button>
                }
                </ButtonGroup>
            </Col>
          </Row>
          
          ))
}
                </div>
              </Col>
              <Col xs={{ span:12, order: 1 }} sm={{ span:12, order: 1 }} lg={{ span:3, order: 4 }}>
              
                  
                  
                  <div sx={{
                        width: '100%',
                        padding: '0px',
                        paddingBottom: '20px'
                      }}>
                      
                      
                    {  
                      // <h6 className="text-uppercase mb-4 font-weight-bold" sx={{color:appsContext.state.theme.bodyTextColor}}>Tools</h6>
                      // <Button sx={{ marginBottom: '2px' }} variant="dark" href="#!" size="md">
                      //   <FontAwesomeIcon
                      //     icon={faUpload}
                      //     size="lg"
                      //     sx={{
                      //       marginRight: '0.5rem'
                      //     }}
                      //   />{' '}
                      //   Video Upload
                      // </Button>
                      }
{
                      
                      <Picker 
                        title={ ((appsContext.state.isUploading) ? 'UPLOADING '+Math.round(appsContext.state.uploadCompleted*100)+'% ' : 'Upload Video')}
                        theme={
                                { photoPickerButton: {
                                                              'backgroundColor': '#2e4ea3', 
                                                              'border': 'none' , 
                                                              'padding': '10px',
                                                              // position: 'relative',
                                                               width: '100%',
                                                               color: 'white',
                                                                                  // height: 'auto'
                                                                                  // objectFit: 'cover',
                                                                                  'borderRadius': '3px',
                                                                                  
                                                                                  // '&:hover': {
                                                                                  //   backgroundColor:'pink'
                                                                                  //    // WebkitFilter: 'grayscale(5%);',
                                                                                  //    // filter: 'grayscale(50%)',
                                                                                  // },
                                                                                  display: (appsContext.state.isUploading ? 'none':'block')
                                                            }}
                        }
                        onPick={ data => {
                            // console.log(data)
                            uploader.isUploading = true

                            const fext = data.name.match(/(\.mov)|(\.mp4)|(\.m4v)|(\.m4p)|(\.mpeg)|(\.mpg)|(\.avi)|(\.wmv)|(\.flv)/gi)
                            // console.log('fext:', fext)
                            
                            // match "video" and reject if not a video
                            // console.log('data.type:', data.type)
                            
                            if(data.type.match(/video/i)){
                                
                                console.log('video type ok.')
                                Storage.put(data.name, data.file, {
                                  level: 'private',
                                  contentType: data.type,
                                  progressCallback(progress) {
                                        // console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
                                        
                                        uploader.uploadCompleted = progress.loaded/progress.total
                                        // appsContext.state.uploadCompleted = progress.loaded/progress.total
                                        appsContext.dispatch({ type: "UPLOADER", uploader })
                                  }
                                })
                                .then (result => {
                                  // console.log(result)
                                  uploader.isUploading = false
                                  uploader.uploadCompleted = 0
                                  uploader.uploadCounter++
                                  appsContext.dispatch({ type: "UPLOADER", uploader })
                                })
                                .catch(err => {
                                  console.log(err)
                                  uploader.isUploading = false
                                  uploader.uploadCompleted = 0
                                  uploader.uploadCounter++
                                  appsContext.dispatch({ type: "UPLOADER", uploader })
                                });

                            }else{

                              // console.log('not a video!')
                              // appsContext.dispatch({ type:"TOASTS", toasts:{ show:true, display:'block', displayTopPosition:'-50px', title:"Error", body: "Upload Video Files Only!" }})
                              appsContext.dispatch({ type:"ERRORALERTS", errorAlerts: {...appsContext.state.errorAlerts, UploadVideoErrorAlert:{ show:true, heading:"Error", body: "Upload Video Files Only!" }} })

                            }


                            
                          }


                        }
                        // onClick={e => handleUpload(e, 'videoUpload')}
                        track
                        >
                      </Picker>
                      
                    }
{
                      (appsContext.state.isAdmin) ? <Button sx={{ marginTop: '2px', marginBottom: '2px', width: '100%' }} style={{border:'none', backgroundColor:'#2e4ea3', fontSize:'.8rem', padding:'15px'}} href="#!" size="md" onClick={e => handleJobTemplate(e)}>CREATE WATERMARK TEMPLATE</Button> 
                    : null
                  }
{
  // console.log('appsContext.state.user.attributes.picture:',appsContext.state.user.attributes.picture)
}
                  <Accordion>
                        <Card>
                          <Card.Header>Video Watermark <Accordion.Toggle as={Button} variant="link" eventKey="0">Edit</Accordion.Toggle></Card.Header>
      
                          <Card.Body>
                          <Accordion.Collapse eventKey="0">
                <div>            
                       {// show existing video watermark

                    
                  (typeof appsContext.state.user.attributes.picture != 'undefined') ?
                    <span style={{marginBottom:'10px'}}>
                    <img 
                      style={{width:'250px'}}
                      src={"https://shotwirecomfc53e21aa63e40c7b4f60c37fdf92c0e01858-master.s3-us-west-2.amazonaws.com/public/"+appsContext.state.user.attributes.picture+'?'+appsContext.state.wmUploader.uploadCounter}                    
                      onLoad={e => handleImgSize(e)}
                    />
                        </span>
                        :     
                       <span>
                              <b>For Your Custom Watermark:</b><br />
                              Upload a PNG or TGA file for your logo to watermark your videos.<br />
                              
                              
                              <b>Aspect ratio:</b> Use any aspect ratio; it doesn't need to match the aspect ratio of the underlying video.<br />
                              <b>Size in pixels:</b> Use any size. If the overlaid graphic is larger than the output video frame, the service crops the graphic at the edge of the frame.
                              Click UPDATE WATERMARK after uploading a new watermark for the first time.<br />
                              <br />
                              Videos will include a Shotwire watermark if there is no custom watermark.<br />
                            </span>
                     }       
                            
<UploadImageErrorAlert />

                              <Picker 
                                // title={ ((appsContext.state.wmIsUploading) ? 'UPLOADING '+Math.round(appsContext.state.wmUploadCompleted*100)+'%' : 'Upload Image')}
                                title={ ((appsContext.state.wmUploader.isUploading) ? 'UPLOADING '+Math.round(appsContext.state.wmUploader.uploadCompleted*100)+'%' : 'Upload Image')}
                                theme={MyTheme}
                                onPick={ data => {
                                    // console.log(data)
                                    wmUploader.isUploading = true
                                    
                                    const fext = data.name.match(/(\.tga)|(\.png)/gi)
                                    
                                    if(fext !== null ){
                                        let fdata = null
                                        // console.log('fext:', fext)
                                        //throw error if fext not correct
                                        // if(fext !== null){  
                                          // console.log('file good.')
                                          fdata = data.file
                                                                            
                                        // }

                                        Storage.put('video-overlay-'+appsContext.state.user.username+fext, fdata, {
                                            contentType: data.type,
                                            metadata: { name: data.name },
                                            progressCallback(progress) {
                                                  // console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
                                                  
                                                  wmUploader.uploadCompleted = progress.loaded/progress.total
                                                  // appsContext.state.uploadCompleted = progress.loaded/progress.total
                                                  appsContext.dispatch({ type: "WMUPLOADER", wmUploader })
                                            }
                                        })
                                        .then (result => {
                                          console.log('s3put result=',result)
                                          wmUploader.isUploading = false
                                          wmUploader.uploadCompleted = 0
                                          wmUploader.uploadCounter = new Date().getTime()
                                          appsContext.dispatch({ type: "WMUPLOADER", wmUploader })
                                          let res = updateUserWatermark(result.key)
                                          // console.log('res:', res)
                                          appsContext.dispatch({ type: "JOBTEMPLATE", jobTemplate:{ doJobTemplateUpdate: 1 } })
                                          // createJobTemplate()
                                          // setTimeout( () => createJobTemplate(), 2000)
                                          // console.log('create job initiated!')
                                        })
                                        .catch(err => {
                                          console.log('put err:',err)
                                          wmUploader.isUploading = false
                                          wmUploader.uploadCompleted = 0
                                          wmUploader.uploadCounter = appsContext.state.wmUploader.uploadCounter
                                          appsContext.dispatch({ type: "WMUPLOADER", wmUploader })
                                        });

                                    }else{
                                      // console.log('not a png or tga file!')
                                      // appsContext.dispatch({ type:"TOASTS", toasts:{ show:true, display:'block', displayTopPosition:'-50px', title:"Error", body: "Upload Image PNG or TGA Files Only!" }})
                                      appsContext.dispatch({ type:"ERRORALERTS", errorAlerts: { ...appsContext.state.errorAlerts, UploadImageErrorAlert:{ show:true, heading:"Error", body: "Upload Image PNG or TGA Files Only!" } } })

                                    }
                                    
                                  }


                                }
                                // onClick={e => handleUpload(e, 'videoUpload')}
                                track
                                >
                              </Picker>

                            

                            {
                              (typeof appsContext.state.user.attributes.picture != 'undefined') ?
                                <Accordion.Toggle as={Button} variant="link" eventKey="1">Watermark Settings</Accordion.Toggle>:
                                ''
                              }
                            </div>
    
    </Accordion.Collapse>


  
  <Accordion.Collapse eventKey="1">
  <Card.Text>
  {
    (typeof appsContext.state.wmarkForm != 'undefined' && typeof appsContext.state.user.attributes.picture != 'undefined') ?
  
  
                              <Form sx={{
                                padding: '5px'
                                }} 
                                onSubmit={e => handleSubmit(e)}>
                                  <Form.Group as={Row} controlId="wmTop">
                                  <Form.Label column sm="5">
                                    Top
                                  </Form.Label>
                                  <Col sm="7">
                                    <Form.Control as="input" size="sm" placeholder="Top" value={(appsContext.state.wmarkForm.top == null)?'':appsContext.state.wmarkForm.top} onChange={e => handleWmFormChange(e)} />
                                  </Col>
                                  </Form.Group>
                                  <Form.Group as={Row} controlId="wmLeft">
                                  <Form.Label column sm="5">
                                    Left
                                  </Form.Label>
                                  <Col sm="7">
                                    <Form.Control as="input" size="sm" placeholder="Left" value={(appsContext.state.wmarkForm.left == null) ? '' : appsContext.state.wmarkForm.left} onChange={e => handleWmFormChange(e)} />
                                  </Col>
                                  </Form.Group>
                                  <Form.Group as={Row} controlId="wmWidth">
                                  <Form.Label column sm="5">
                                    Width
                                  </Form.Label>
                                  <Col sm="7">
                                    <Form.Control as="input" size="sm" placeholder="Width" value={(appsContext.state.wmarkForm.width == '') ? appsContext.state.videoWatermark.width : appsContext.state.wmarkForm.width} onChange={e => handleWmFormChange(e)} />
                                  </Col>
                                  </Form.Group>
                                  <Form.Group as={Row} controlId="wmHeight">
                                  <Form.Label column sm="5">
                                    Height
                                  </Form.Label>
                                  <Col sm="7">
                                    <Form.Control as="input" size="sm" placeholder="Height" value={(appsContext.state.wmarkForm.height=='') ? appsContext.state.videoWatermark.height : appsContext.state.wmarkForm.height} onChange={e => handleWmFormChange(e)} />
                                  </Col>
                                  </Form.Group>
                                  <Form.Group as={Row} controlId="wmOpacity">
                                  <Form.Label column sm="5">
                                    Opacity
                                  </Form.Label>
                                  <Col sm="7">
                                    <Form.Control as="input" size="sm" placeholder="Opacity" value={(appsContext.state.wmarkForm.opacity == null) ? '' : appsContext.state.wmarkForm.opacity} onChange={e => handleWmFormChange(e)} />
                                  </Col>
                                  </Form.Group>
                                  <Form.Group as={Row} controlId="wmLayer">
                                  <Form.Label column sm="5">
                                    Layer
                                  </Form.Label>
                                  <Col sm="7">
                                    <Form.Control as="input" size="sm" placeholder="Layer" value={(appsContext.state.wmarkForm.layer == null) ? '' : appsContext.state.wmarkForm.layer} onChange={e => handleWmFormChange(e)} />
                                  </Col>
                                  </Form.Group>
                                </Form>
                                


                                :

                                ''
              }
              {
                 (typeof appsContext.state.user.attributes.picture != 'undefined') ?
                                            <Button sx={{ marginTop: '2px', marginBottom: '2px', width: '100%' }} style={{backgroundColor:'#2e4ea3', fontSize:'.8rem', padding:'15px'}} href="#!" size="md" onClick={e => handleJobTemplate(e)}>
                                            
                                            {(appsContext.state.jobTemplate.isUpdating==true) ? 
                                                          (<span> Saving <FontAwesomeIcon
                                                            icon={faSpinner}
                                                            size="sm"
                                                            sx={{
                                                              // marginRight: '0.5rem'
                                                            }}
                                                            spin
                                                          /> </span>)
                                                          : 
                                                          ('UPDATE WATERMARK')
                                                        }
                                            </Button>
                                            :
                                            ''
              }
              </Card.Text>
               </Accordion.Collapse>
                        
                        </Card.Body>
                          
                        </Card>
                      </Accordion>
            

                  
                  </div>
                  
                  <div sx={{
                        width: '100%',
                        padding: '10px',

                      }}>
                    
                   
                    
                    
                    <br /><br />
                    
                    

                  
                    


                    
                  </div>

                    <div sx={{
                        width: '100%',
                        padding: '10px',
                        // backgroundColor: '#444'
                      }}
                      >
                   
                      
                      
                      
                      
                    </div>

              </Col>
            </Row>
          </Container>

           
           </Layout>
           )
  }else{
    return(
          <Layout>
          <Container>

          { /*<h3>No Video Info</h3>*/ }
          
          <div sx={{
  padding: '10px',
 }} >
 {
  <span>Refresh Profile</span>
          // <FontAwesomeIcon
          //   icon={faSpinner}
          //   size="2x"
          //   sx={{
          //     // marginRight: '0.5rem'
          //   }}
          //   spin
          // />
}

          </div>
          </Container>
          </Layout>
          )
  }

}else{
        return(
          <Layout>
          <Container>

          { <h3>Not Authorized</h3> }
          
          </Container>
          </Layout>
          )
}

 
}
{ 
 //                        appsContext.state.videoPlayer.isDownloading ? (
 //          <div sx={{
 //  paddingRight: '10px',
 // }} >
 //          <FontAwesomeIcon
 //            icon={faSpinner}
 //            size="sm"
 //            sx={{
 //              // marginRight: '0.5rem'
 //            }}
 //            spin
 //          />
 //          </div>
 //        ) : (
 //          <div sx={{
 //  padding: '0px',
 // }}></div>
 //        )
    }

//if we make our own controls 
// { appsContext.state.videoPlayer.playing ? (
//                 <Button variant="dark" href="#!" size="md" onClick={handlePause}>
//                         <FontAwesomeIcon
//                           icon={faPause}
//                           size="lg"
//                           sx={{
//                             marginRight: '0.5rem'
//                           }}
//                         />
//                       </Button>
// ):(
//                       <Button variant="dark" href="#!" size="md" onClick={handlePlay}>
//                         <FontAwesomeIcon
//                           icon={faPlay}
//                           size="lg"
//                           sx={{
//                             marginRight: '0.5rem'
//                           }}
//                         />
//                       </Button>
//             )   
// }

// <p>
//           Query String:<br />
//           ?setId={ location.state.assetObj.realSetId }&setDate={ location.state.assetObj.setDate }&setName={ location.state.assetObj.name }&assetName={ location.state.assetName }
//           </p>
// <Layout>
//     <SEO title="Celebrity Candids" />
//     <h1>Hi from the second page</h1>
//     <p>Celebrity Candids</p>
//     <Link to="/">Go back to the homepage</Link>
//   </Layout>

export default ControlCenterPage