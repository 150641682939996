
export  const getAssetDeltaObject = `query GetShotwireAssetsDelta($id: ID!) {
    getShotwireAssetsDelta(id: $id){
      id
      archived
      realSetId
      name
      highRezFileName
      setDate
      category
      uploader    
      caption
      headline
      byline
      keywords
      city
      suppCat
      thumbNailUrl
      mp4Urls      
      dashUrl
      srcMediainfo
      cmafDashUrl
      cmafHlsUrl
    }
  }`

 export const getLegacyShotwireAsset = `query GetLegacyShotwireAsset( $name: String!, $realSetId: Int!) {
    getLegacyShotwireAsset(name: $name, realSetId: $realSetId ){
      items {
          id
          category
          setDate 
          name
          fileName
          fileInfo
          fileSize
          assetType
          highRezFileName
          companyId
          realSetId
          assetId
          headline
          caption
          keywords
          byline
          suppCat
          city
          video_length
          archived
          category
          thumbNailUrl
          mp4Urls
          dashUrl
          hlsUrl
          cmafDashUrl
          cmafHlsUrl
        }
    }
  }`

export const assetDeltaUpdate = `mutation UpdateShotwireAssetsDelta($input: UpdateShotwireAssetsDeltaInput!) {
      updateShotwireAssetsDelta(input: $input) {
        id
        category
        assetId
        name
      }
    }
    `;

    export const getShotwireAssetsDeltaPublic = /* GraphQL */ `
  query GetShotwireAssetsDelta($id: ID!) {
    getShotwireAssetsDelta(id: $id) {
      id
      assetId
      assetType
      fileName
      fileInfo
      fileSize
      name
      realSetId
      setDate
      companyId
      caption
      headline
      instructions
      objectName
      source
      copyright
      keywords
      byline
      fixtureId
      suppCat
      captionWriter
      city
      state
      country
      countryCode
      originalTransmissionReference
      releaseDate
      createdDate
      modified
      video_format
      video_bitrate
      video_width
      video_height
      video_fps
      video_aspect
      audio_codec
      audio_format
      audio_bitrate
      audio_rate
      audio_nch
      length
      video_frames
      video_length
      audio_length
      archived
      s3srcUrl
      proxyInfo
      apiSource
      thumbNailUrl
      mp4Urls
      hlsUrl
      dashUrl
      workflowStatus
      category
      searches
      uploader
      timestamp
      license
      marketplace
      srcMediainfo
      owner
      cmafDashUrl
    }
  }
`;
